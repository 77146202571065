import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import VisitFormIcon from '@mui/icons-material/FeedRounded'
import { useCallback, useEffect, useState } from 'react'
import { Visit } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import VisitsList from '../../../components/Visits/VisitsList'
import VisitsTopButtons from '../../../components/Visits/VisitsTopButtons'
import CsvVisitsDialog from '../../../components/Visits/CsvVisitsDialog'
import { VISITS_DB } from '../../../services/api/databases'
import { useInterval } from '../../../hooks/useInterval'
import ActiveVisits from '../../../components/Visits/ActiveVisits'
import TodayVisits from '../../../components/Visits/TodayVisits'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import ApiHandler from '../../../services/api/apiHandler'
import { getVisitsQuery } from './VisitsPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import ReturnButton from '../../../components/PageComponents/ReturnButton'
import GridCard from '../../../components/PageComponents/GridCard'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'

// specific email from users that can edit visits
const EMAIL_WHO_CAN_EDIT = [
  'dranger@cabansystems.com',
  'rsanchez@cabansystems.com',
  'jrua@cabansystems.com',
  'nvasquez@cabansystems.com',
  'rubenjoselascarro.delreal@cabansystems.com',
  'jfcardenas@cabansystems.com',
  'mdiaz@cabansystems.com',
  'colombiaops@cabansystems.com',
]

const VisitsPage = () => {
  const { nocappUser } = useNocAppAuth()
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const [visits, setVisits] = useState<Visit[]>([])
  const [todayVisits, setTodayVisits] = useState<Visit[]>([])
  const [activeVisits, setActiveVisits] = useState<Visit[]>([])
  const [limit, setLimit] = useState<number | 'all'>(100)
  const [loading, setLoading] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [openCsvDownload, setOpenCsvDownload] = useState(false)

  useEffect(() => {
    if (nocappUser!.profile === 'admin' || nocappUser!.profile === 'noc') {
      setCanEdit(true)
    }

    // Controls specific users that can edit visits info
    if (EMAIL_WHO_CAN_EDIT.includes(nocappUser!.email)) {
      setCanEdit(true)
    }
  }, [nocappUser])

  const getVisits = useCallback(async () => {
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Queries
    const queries = getVisitsQuery()
    // Profile distinction
    if (nocappUser!.profile !== 'admin' && nocappUser!.profile !== 'noc') {
      queries.visits += ` WHERE ${VISITS_DB}.country = '${nocappUser!.country}'`
      queries.todayVisits += ` AND ${VISITS_DB}.country = '${
        nocappUser!.country
      }'`
      queries.activeVisits += ` AND ${VISITS_DB}.country = '${
        nocappUser!.country
      }'`
    }
    // Set limit
    if (limit !== 'all') {
      queries.visits += ` ORDER BY id DESC LIMIT ${limit}`
      queries.activeVisits += ` ORDER BY id DESC LIMIT ${limit}`
    }
    // Get
    const [visitsData, todayVisitsData, activeVisitsData] = await Promise.all([
      apiHandler.getList(queries.visits),
      apiHandler.getList(queries.todayVisits),
      apiHandler.getList(queries.activeVisits),
    ])
    //
    setVisits(visitsData)
    setTodayVisits(todayVisitsData)
    setActiveVisits(activeVisitsData)
    setLoading(false)
  }, [limit, nocappUser])

  useEffect(() => {
    getVisits()
  }, [getVisits])

  useInterval(() => {
    getVisits()
  }, 60000)

  const exportVisitsInCsv = async ({
    startDate: start_date,
    endDate: end_date,
  }: {
    startDate: string
    endDate: string
  }) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const res = await fetch(`/api/visits/report`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'post',
      body: JSON.stringify({
        start_date,
        end_date,
      }),
    })
    const csv = await res.text()

    return csv
  }

  return (
    <PageComponent
      title="Visits"
      loading={loading}
      centerComponent={
        <VisitsTopButtons exportVisits={() => setOpenCsvDownload(true)} />
      }
    >
      <GridContainer>
        <ReturnContainer hoveranimation>
          <ReturnButton />
        </ReturnContainer>
        <CreateNewVisitContainer
          onClick={() => navigate('form')}
          hoveranimation
        >
          <div
            style={{
              flex: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VisitFormIcon fontSize="large" />
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
            Fill visit form
          </div>
        </CreateNewVisitContainer>
        <TodayVisitsContainer>
          <TodayVisits visits={todayVisits} />
        </TodayVisitsContainer>
        <ActiveVisitsContainer>
          <ActiveVisits visits={activeVisits} updateList={getVisits} />
        </ActiveVisitsContainer>
        <VisitsListContainer>
          <VisitsList
            visits={visits}
            limit={limit}
            canEdit={canEdit}
            setLimit={setLimit}
            updateList={getVisits}
          />
        </VisitsListContainer>
      </GridContainer>
      <CsvVisitsDialog
        open={openCsvDownload}
        setOpen={setOpenCsvDownload}
        exportVisits={exportVisitsInCsv}
      />
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridTemplateRows: 'repeat(6, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))
const ReturnContainer = styled(GridCard)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const CreateNewVisitContainer = styled(GridCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const TodayVisitsContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 2,
  gridRowEnd: 4,
  display: 'flex',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const ActiveVisitsContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 4,
  gridRowEnd: 7,
  display: 'flex',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const VisitsListContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 3,
  gridColumnEnd: 7,
  gridRowStart: 1,
  gridRowEnd: 7,
  display: 'flex',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 2,
  },
}))

export default VisitsPage
