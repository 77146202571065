export const sitesIndexFetch = async (token: string) => {
  const countriesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/countries`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const sitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?active=true`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const siteDashboardFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/dashboard`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const countriesData = await countriesFetch.json()
  const sitesData = await sitesFetch.json()
  const siteDashboardData = await siteDashboardFetch.json()

  return {
    sitesData: sitesData.message,
    countriesData: countriesData.message,
    dashboard: siteDashboardData.message,
  }
}

export const sitesByCountryFetch = async (token: string, countryId: number) => {
  const sitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?active=true&country_id=${countryId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const sitesData = await sitesFetch.json()

  return countryId === -1 ? sitesIndexFetch(token) : sitesData.message
}
