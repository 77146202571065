import {
  Button,
  CircularProgress,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { SiteReport, BasicReportKpis, AsstMgmDevice } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import ReportsTopBar from '../../../components/Reports/ReportsTopBar'
import BasicReportKPIs from '../../../components/Reports/BasicReportKPIs'
import BasicReportTemplates from '../../../components/Reports/BasicReportTemplates'
import {
  getDevicesFetch,
  getReportInfoFromSiteFetch,
} from '../reportPagesQueries'
import {
  basicReportDataForExport,
  basicReportKpis,
} from './BasicReportPage.functions'
import {
  acEnergy,
  activeLoad,
  downtimeHours,
  hoursOfGenset,
  loadPowerComsuption,
  solarEnergyFunction,
} from '../../../utils/reportsFunctions'
import PageComponent from '../../../components/PageComponents/PageComponent'

const PADDING = 20
const PRECISION_LENGHT = 3
const TABLE_TITLES = [
  'Date',
  'Hour',
  'Load Current [A]',
  'Battery Current [A]',
  'Rectifier Current [A]',
  'MPPT Output Current [A]',
  'System voltage [V]',
  'Rectifier Voltage [V]',
  'MPPT Output Voltage [V]',
  'Solar Energy Generation [kWh]',
  'Load Power Consumption [kWh]',
  'AC Energy [kWh]',
  'Downtime Hours',
  'Active Load',
  'Hours of Genset',
]

const BasicReportPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [sites, setSites] = useState<AsstMgmDevice[]>([])
  const [selectedSite, setSelectedSite] = useState(-1)
  const [siteData, setSiteData] = useState<AsstMgmDevice | null>(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const [reportData, setReportData] = useState<SiteReport[]>([])
  const [kpi, setKpi] = useState<BasicReportKpis | null>(null)
  const [showKpi, setShowKpi] = useState(false)
  const [showTemplates, setShowTemplates] = useState(false)

  const getSites = useCallback(async () => {
    setLoadingData(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const data = await getDevicesFetch(token)
    //
    setSites(data)
    setLoadingData(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    getSites()
  }, [getSites])

  const getSiteReport = useCallback(async () => {
    if (selectedSite === -1 || startDate === '' || endDate === '') {
      return
    }
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    setLoadingData(true)
    // Queries
    const data = await getReportInfoFromSiteFetch(
      selectedSite,
      new Date(startDate).getTime() / 1000,
      new Date(endDate).getTime() / 1000,
      token
    )
    setReportData(data)
    //
    setLoadingData(false)
  }, [selectedSite, startDate, endDate, getAccessTokenSilently])

  useEffect(() => {
    getSiteReport()
  }, [getSiteReport])

  const exportKPI = () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    // Kpis
    const kpis = basicReportKpis(reportData, startDate, endDate)
    //
    const csv = `Availability,${(kpis.availability * 100).toFixed(
      PRECISION_LENGHT
    )}%,\nSolar Energy Generation Avg (kWh) x day,${kpis.solarEnergyAvgPerDay.toFixed(
      PRECISION_LENGHT
    )}\nLoad Power Consumption Avg (kW),${kpis.loadEnergyAvg.toFixed(
      PRECISION_LENGHT
    )}\nAC Energy Generation Avg (kW),${kpis.acEnergyGenerationAvgPerDay.toFixed(
      PRECISION_LENGHT
    )}`
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${siteData?.name} basic report kpis ${dateLog}.csv`
    hiddenElement.click()
  }

  const showKpiHandler = () => {
    // Kpis
    const kpis = basicReportKpis(reportData, startDate, endDate)
    //
    const kpiData: BasicReportKpis = {
      avgLoad: kpis.loadEnergyAvg,
      avgGridEnergyUsage: kpis.acEnergyGenerationAvgPerDay,
      avgSolarGeneration: kpis.solarEnergyAvgPerDay,
      energyReduction: kpis.energyReduction,
      gpa: kpis.gpa,
      gridAvailability: kpis.availability,
      totalBatteryThroughput: kpis.batteryEnergy,
      totalGridEnergyUsage: kpis.acTotal,
      totalLoadEnergyConsumption: kpis.loadEnergyTotal,
      totalSolarEnergyGeneration: kpis.solarEnergyTotal,
    }
    setKpi(kpiData)
    setShowKpi(true)
  }

  const exportData = () => {
    const currentDate = new Date()
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    let csv = `${TABLE_TITLES.join(',')}\n`
    reportData.map((data) => {
      const values = basicReportDataForExport(data, PRECISION_LENGHT)
      csv += `${values.join(',')}\n`
      return data
    })
    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${siteData?.name} basic report ${dateLog}.csv`
    hiddenElement.click()
  }

  return (
    <PageComponent
      title="Reports"
      loading={loadingData}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TopBarContainer>
        <ReportsTopBar
          title="Basic report"
          rightRender={
            <TextField
              select
              variant="filled"
              value={selectedSite}
              onChange={(e) => {
                const id = e.target.value
                const siteInfo = sites.find((site) => site.id === +id)
                setSelectedSite(+id)
                setSiteData(siteInfo!)
              }}
              size="small"
              label="Select a site"
              style={{ width: 150 }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                },
              }}
            >
              <MenuItem value="" />
              {sites.map((site, i) => (
                <MenuItem value={site.id} key={i}>
                  {site.name}
                </MenuItem>
              ))}
            </TextField>
          }
        />
      </TopBarContainer>
      <PageBody>
        <DataContainer>
          <DatesExportContainer>
            <DatesContainer>
              <span style={{ marginRight: 5 }}>From</span>
              <TextField
                style={{ marginRight: 10 }}
                size="small"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="datetime-local"
              />
              <span style={{ marginRight: 5 }}>To</span>
              <TextField
                size="small"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="datetime-local"
              />
              {loadingData && (
                <CircularProgress style={{ marginLeft: 10 }} size={25} />
              )}
            </DatesContainer>
            {siteData && startDate !== '' && endDate !== '' && (
              <div>
                <Button
                  variant="outlined"
                  style={{ marginRight: 5, color: 'inherit' }}
                  onClick={() => setShowTemplates(true)}
                >
                  Show templates
                </Button>
                <Button
                  variant="outlined"
                  style={{ marginRight: 5, color: 'inherit' }}
                  onClick={showKpiHandler}
                >
                  Show site KPI
                </Button>
                <Button style={{ marginRight: 5 }} onClick={exportKPI}>
                  Export KPI
                </Button>
                <Button onClick={exportData}>Export data</Button>
              </div>
            )}
          </DatesExportContainer>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {TABLE_TITLES.map((title, i) => (
                    <TableTitles key={i}>{title}</TableTitles>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.length !== 0 &&
                  reportData.map((data, i) => (
                    <TableRow hover key={i}>
                      <TableCell>{data.date}</TableCell>
                      <TableCell>{data.time}</TableCell>
                      <TableCell>{data.metric_load_current_estimate}</TableCell>
                      <TableCell>{data.core_ess_current}</TableCell>
                      <TableCell>
                        {data.tel_status_rect_measured_current}
                      </TableCell>
                      <TableCell>{data.core_mppt_output_current}</TableCell>
                      <TableCell>{data.core_system_link_voltage}</TableCell>
                      <TableCell>
                        {data.tel_status_rect_measured_voltage}
                      </TableCell>
                      <TableCell>{data.core_mppt_output_voltage}</TableCell>
                      <TableCell>
                        {solarEnergyFunction(
                          data.core_mppt_output_voltage,
                          data.core_mppt_output_current
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {(
                          loadPowerComsuption(
                            data.core_system_link_voltage,
                            data.metric_load_current_estimate
                          ) / 1000
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {(
                          acEnergy(
                            data.tel_status_rect_measured_current,
                            data.tel_status_rect_measured_voltage
                          ) / 1000
                        ).toFixed(PRECISION_LENGHT)}
                      </TableCell>
                      <TableCell>
                        {downtimeHours(data.metric_load_current_estimate)}
                      </TableCell>
                      <TableCell>
                        {activeLoad(
                          +(
                            loadPowerComsuption(
                              data.core_system_link_voltage,
                              data.metric_load_current_estimate
                            ) / 1000
                          ).toFixed(PRECISION_LENGHT)
                        )}
                      </TableCell>
                      <TableCell>
                        {hoursOfGenset(data.tel_status_rect_measured_current)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DataContainer>
      </PageBody>
      {kpi && <BasicReportKPIs open={showKpi} setOpen={setShowKpi} kpi={kpi} />}
      {siteData && (
        <BasicReportTemplates
          open={showTemplates}
          setOpen={setShowTemplates}
          cabanId={siteData!.name!}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </PageComponent>
  )
}

const TopBarContainer = styled('div')(() => ({
  width: '100%',
  height: 80,
  display: 'flex',
}))
const PageBody = styled('div')({
  width: '100%',
  height: `calc(100vh - 80px - ${PADDING}px)`,
  display: 'flex',
  flexDirection: 'column',
})
const DataContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  padding: 10,
}))
const DatesExportContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const DatesContainer = styled('div')(() => ({
  display: 'flex',
  fontWeight: 'bold',
  alignItems: 'center',
}))
const TableTitles = styled(TableCell)({
  fontWeight: 'bold',
})

export default BasicReportPage
