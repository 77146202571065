import { NocAppAppProfiles } from 'nocapp-types'
import {
  ALERTS_LOG_DB,
  SITES_DB,
  VISITS_DB,
} from '../../services/api/databases'

export const dashboardFetch = async (token: string) => {
  const sitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?active=true`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const sites = await sitesFetch.json()
  return {
    sites: sites.message,
  }
}

export const dashboardQueries = (
  profile: NocAppAppProfiles,
  country: string
) => {
  // Dates handler
  const date = new Date()
  const currentDate = `${date.getFullYear()}-${
    (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
  }-${(date.getDate() < 10 ? '0' : '') + date.getDate()}`
  date.setDate(date.getDate() - 7)
  const sevenDaysAgoDate = `${date.getFullYear()}-${
    (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
  }-${(date.getDate() < 10 ? '0' : '') + date.getDate()}`
  // Queries
  const lastSevenAlerts = `SELECT * FROM  ${ALERTS_LOG_DB} ORDER BY created_at_date DESC LIMIT 7`
  let lastSevenDaysAlertsCount = `SELECT COUNT(id) as count FROM  ${ALERTS_LOG_DB} WHERE DATE(created_at_date) >= '${sevenDaysAgoDate}' GROUP BY created_at_date, id ORDER BY created_at_date DESC`
  let visitsQuery = `SELECT * FROM ${VISITS_DB} WHERE DATE(start_datetime) = '${currentDate}' ORDER BY start_datetime DESC`
  let sitesQuery = `SELECT * FROM  ${SITES_DB} WHERE active = 1`
  // Profile distinctions
  if (profile !== 'admin' && profile !== 'noc') {
    lastSevenDaysAlertsCount = `SELECT * FROM ${ALERTS_LOG_DB} WHERE DATE(created_at_date) = '${currentDate}' AND country = '${country}' GROUP BY created_at_date, id ORDER BY created_at_date DESC`
    visitsQuery = `SELECT * FROM ${VISITS_DB} WHERE DATE(start_datetime) = '${currentDate}' AND country = '${country}' ORDER BY start_datetime DESC`
    sitesQuery = `SELECT * FROM ${SITES_DB} WHERE active = 1 AND country = '${country}'`
  }

  return {
    lastSevenDaysAlertsCount,
    lastSevenAlerts,
    visits: visitsQuery,
    sites: sitesQuery,
  }
}
