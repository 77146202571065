/* eslint-disable no-nested-ternary */
import {
  Button,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { AsstMgmSiteList } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'

interface ActiveInactiveSitesListProps {
  active: AsstMgmSiteList[]
  inactive: AsstMgmSiteList[]
}

const ActiveInactiveSitesList = ({
  active,
  inactive,
}: ActiveInactiveSitesListProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const theme = useTheme()
  const [sites, setSites] = useState<AsstMgmSiteList[]>([])
  const [filteredSites, setFilteredSites] = useState<AsstMgmSiteList[]>([])
  const [listType, setListType] = useState<'all' | 'active' | 'inactive'>('all')
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setSites([...active, ...inactive])
    setFilteredSites([...active, ...inactive])
  }, [active, inactive])

  const changeSiteTypeHandler = (value: 'all' | 'active' | 'inactive') => {
    setListType(value)
    switch (value) {
      case 'active':
        setSites(active)
        setFilteredSites(active)
        break
      case 'inactive':
        setSites(inactive)
        setFilteredSites(inactive)
        break
      case 'all':
      default:
        setSites([...active, ...inactive])
        setFilteredSites([...active, ...inactive])
        break
    }
  }

  const searchSiteHandler = useCallback(
    (value: string) => {
      function compareObjects(o1: any, o2: any) {
        let k = ''
        for (k in o1) if (o1[k] !== o2[k]) return false
        for (k in o2) if (o1[k] !== o2[k]) return false
        return true
      }

      function itemExists(haystack: any, needle: any) {
        for (let i = 0; i < haystack.length; i++)
          if (compareObjects(haystack[i], needle)) return true
        return false
      }

      const results: AsstMgmSiteList[] = []
      sites.forEach((siteInfo) => {
        const { site } = siteInfo
        for (const key in site) {
          if (Object.prototype.hasOwnProperty.call(site, key)) {
            // @ts-ignore
            const element: string =
              // @ts-ignore
              site[key] == null ? '' : site[key].toString()
            const formatedElement = element
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            const formatedValue = value
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            if (formatedElement.search(formatedValue) !== -1) {
              if (!itemExists(results, site)) {
                results.push({ site })
              }
            }
          }
        }
      })
      setSearchValue(value)
      setFilteredSites(results)
    },
    [sites]
  )

  const exportSites = async () => {
    const currentDate = new Date()
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const dateLog = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
    const headers = [
      'caban_id',
      'modem_id',
      'site_name',
      'country',
      'active',
      'created_at',
      'commentaries',
      'site_id',
      'contracted_load',
      'customer',
      'customer_id',
      'owner',
      'owner_id',
      'type',
      'supervisor',
      'genset',
      'grid',
      'grid_percentage',
      'solar_panels',
      'solar_structures',
      'packs',
      'mppts',
      'rectifiers',
      'cabinet_id',
      'configuration',
      'cabinet_type',
      'battery_model',
      'turn_on_date',
      'alerts_activation_date',
      'migration_date',
      'commencement_date',
      'report_link',
      'atp_link',
      'engineering_data_link',
      'plans_link',
      'latitude',
      'longitude',
      'address',
      'zone',
      'distance',
      'travel_time',
    ]

    const res = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list${
        listType === 'active' || listType === 'inactive'
          ? listType === 'active'
            ? '?active=true'
            : '?active=false'
          : ''
      }`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const data = (await res.json()).message as AsstMgmSiteList[]
    const csv = [headers.join(',')]
    data.map((siteData) => {
      const { site } = siteData
      const { devices, location, customer, owner, supervisor } = site

      const row = [
        site.code,
        devices && devices?.length > 0 ? devices[0].id : '',
        site.name,
        location?.country ? location?.country.name : '',
        site.active ? 1 : 0,
        site.created_date,
        site.comments,
        site.code,
        devices && devices?.length > 0 ? devices[0].contracted_load : '',
        customer ? customer.name : '',
        site.customer_code,
        owner ? owner.name : '',
        site.owner_code,
        site.type,
        supervisor ? supervisor.name : '',
        devices && devices?.length > 0
          ? devices[0].genset_present
            ? 1
            : 0
          : '',
        devices && devices?.length > 0 ? (devices[0].grid_present ? 1 : 0) : '',
        devices && devices?.length > 0 ? devices[0].grid_percentage : '',
        devices && devices?.length > 0 ? devices[0].solar_panel_count : '',
        devices && devices?.length > 0 ? devices[0].solar_structures : '',
        devices && devices?.length > 0 ? devices[0].pack_count : '',
        devices && devices?.length > 0 ? devices[0].mppt_count : '',
        devices && devices?.length > 0 ? devices[0].rectifiers : '',
        devices && devices?.length && devices[0].cabinet
          ? devices[0].cabinet?.name
          : '',
        devices && devices?.length > 0 ? devices[0].configuration : '',
        devices && devices?.length && devices[0].cabinet
          ? devices[0].cabinet?.type
          : '',
        devices && devices?.length && devices[0].battery
          ? devices[0].battery?.name
          : '',
        devices && devices?.length > 0 ? devices[0].turn_on_date : '',
        devices && devices?.length > 0 ? devices[0].alerts_activation_date : '',
        devices && devices?.length > 0 ? devices[0].migration_date : '',
        site.commencement_date,
        devices && devices?.length > 0 ? devices[0].reports_url : '',
        '',
        '',
        '',
        location ? location.latitude : '',
        location ? location.longitude : '',
        location ? location.address1 : '',
        location?.zone ? location?.zone.name : '',
        '',
        '',
      ]
      csv.push(`"${row.join('","')}"`.replaceAll('\n', ' '))

      return site
    })

    const hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
      csv.join('\n')
    )}`
    hiddenElement.target = '_blank'
    hiddenElement.download = `${listType}_sites_table_${dateLog}.csv`
    hiddenElement.click()
  }

  return (
    <Container>
      <SearchContainer>
        <SearchBarContainer>
          <TextField
            fullWidth
            value={searchValue}
            size="small"
            onChange={(e) => searchSiteHandler(e.target.value)}
            variant="filled"
            label="Search for a site"
            placeholder="Device ID, Caban ID, name, dates, etc."
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: 10,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchBarContainer>
        <SelectActiveInactiveContainer>
          <TextField
            select
            fullWidth
            size="small"
            label="Select type"
            variant="filled"
            value={listType}
            onChange={(e) =>
              changeSiteTypeHandler(
                e.target.value as 'all' | 'active' | 'inactive'
              )
            }
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: 10,
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </SelectActiveInactiveContainer>
        <SelectActiveInactiveContainer>
          <Button size="large" onClick={exportSites}>
            Export
          </Button>
        </SelectActiveInactiveContainer>
      </SearchContainer>
      <ListContainer>
        {filteredSites.map((site, i) => (
          <SiteRow to={`/sites/info/${site.site.id}`} key={i}>
            <ActiveDot
              style={{
                backgroundColor: site.site.active
                  ? theme.palette.success.light
                  : theme.palette.error.light,
              }}
            />
            <SiteItemContainer>
              {site.site.devices && site.site.devices.length > 0
                ? site.site.devices[0].id
                : ''}
            </SiteItemContainer>
            <SiteItemContainer>
              {site.site.devices && site.site.devices.length > 0
                ? site.site.devices[0].name
                : ''}
            </SiteItemContainer>
            <SiteItemContainer>{site.site.name || ''}</SiteItemContainer>
            <SiteItemContainer>
              {site.site.location && site.site.location.country
                ? site.site.location.country.name || ''
                : ''}
            </SiteItemContainer>
            <SiteItemContainer>
              {site.site.customer ? site.site.customer.name : ''}
            </SiteItemContainer>
          </SiteRow>
        ))}
      </ListContainer>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const SearchContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
})
const SearchBarContainer = styled('div')({
  flex: 4,
})
const SelectActiveInactiveContainer = styled('div')({
  flex: 1,
})
const ListContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  gap: GLOBAL_PAGE_GAP,
})
const SiteRow = styled(Link)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
  padding: 15,
  borderRadius: 15,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 14,
  gap: GLOBAL_PAGE_GAP,
}))
const SiteItemContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 8,
  borderRadius: 8,
}))
const ActiveDot = styled('div')({
  width: 15,
  height: 15,
  borderRadius: 100,
})

export default ActiveInactiveSitesList
