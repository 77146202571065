import { IconButton, InputAdornment, styled, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  Search as SearchIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { AsstMgmSiteList } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'
import { useKeyPress } from '../../../hooks/useKeyPress'

const SitesSearchBar = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { nocappUser } = useNocAppAuth()
  const [searchValue, setSearchValue] = useState('')
  const searchInput = useRef<HTMLInputElement>(null)
  const [sitesResults, setSitesResults] = useState<AsstMgmSiteList[]>([])
  const [showResults, setShowResults] = useState(false)
  const [canSearchSite, setCanSearchSite] = useState(true)

  useKeyPress(() => {
    setShowResults(false)
    setSearchValue('')
    setSitesResults([])
  }, 27)

  useEffect(() => {
    if (nocappUser!.profile !== 'admin' && nocappUser!.profile !== 'noc') {
      setCanSearchSite(false)
    }
  }, [nocappUser])

  const handleSearchResult = async (value: string) => {
    setSearchValue(value)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    const res = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/search?name=%${value}%`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const data = await res.json()
    setSitesResults(data.message)
    setShowResults(true)
  }

  if (!canSearchSite) {
    return <div />
  }

  return (
    <SearchContainer>
      <TextField
        fullWidth
        ref={searchInput}
        value={searchValue}
        onChange={(e) => handleSearchResult(e.target.value)}
        label="Search for a site"
        placeholder="Device ID, Caban ID, name, dates, etc."
        InputProps={{
          disableUnderline: true,
          style: {
            borderRadius: 10,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchValue('')
                  setShowResults(false)
                  setSitesResults([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showResults && (
        <SearchResults>
          {sitesResults.map((site, i) => (
            <SearchResultRow to={`/sites/info/${site.site.id}`} key={i}>
              <SearchResultItem>{site.site.code}</SearchResultItem>
              <SearchResultItem>
                {site.site.devices && site.site.devices[0]
                  ? site.site.devices[0].id
                  : ''}
              </SearchResultItem>
              <SearchResultItem>{site.site.name}</SearchResultItem>
              <SearchResultItem>
                {site.site.location && site.site.location.country
                  ? site.site.location.country.name
                  : ''}
              </SearchResultItem>
              <SearchResultItem>
                {site.site.customer ? site.site.customer.name : ''}
              </SearchResultItem>
            </SearchResultRow>
          ))}
        </SearchResults>
      )}
    </SearchContainer>
  )
}

const SearchContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const SearchResults = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxHeight: 300,
  top: 60,
  borderRadius: 15,
  padding: 10,
  position: 'absolute',
  zIndex: 5,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '-1px 6px 15px -7px rgba(0,0,0,0.47)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}))
const SearchResultRow = styled(Link)(({ theme }) => ({
  width: '100%',
  height: 50,
  paddingLeft: 15,
  backgroundColor: theme.palette.background.default,
  gap: GLOBAL_PAGE_GAP,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  flexShrink: 0,
  marginBottom: 5,
  borderRadius: 10,
  fontSize: 14,
  transition: '100ms',
  ':hover': {
    backgroundColor: '#e6e6e6',
  },
}))
const SearchResultItem = styled('span')(({ theme }) => ({
  padding: 5,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 5,
}))

export default SitesSearchBar
