import { useCallback, useEffect, useState } from 'react'
import { AsstMgmSiteDashboard } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import CountriesBody from '../../../components/Sites/CountriesBody'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'
import PageComponent from '../../../components/PageComponents/PageComponent'

const SitesByCountryAndClientPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [countriesDivisions, setCountriesDivisions] = useState<
    AsstMgmSiteDashboard['countries']
  >([])

  const getCountriesData = useCallback(async () => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    // Get

    const siteDashboardFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/dashboard`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customerCountriesData = await siteDashboardFetch.json()
    setCountriesDivisions(customerCountriesData.message.countries)
    setLoading(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    getCountriesData()
  }, [getCountriesData])

  return (
    <PageComponent
      title="Sites by country and client"
      centerComponent={<SitesSearchBar />}
      loading={loading}
    >
      <CountriesBody countriesDivisions={countriesDivisions} />
    </PageComponent>
  )
}

export default SitesByCountryAndClientPage
