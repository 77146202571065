export const siteInfoHandle = async (id: string, token: string) => {
  const sitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/${id}/details`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const sitesData = await sitesFetch.json()

  return sitesData.message
}
