import { SiteReport, AsstMgmDevice } from 'nocapp-types'
import { arrayAvg } from '../../../utils/arrayAvg'
import {
  acEnergy,
  batteryEnergy,
  CO2Emission,
  excessEnergy,
  loadPowerComsuption,
  powerChanges,
  solarEnergyFunction,
} from '../../../utils/reportsFunctions'

export const llarReportKpis = (
  reportData: SiteReport[],
  siteData: AsstMgmDevice
) => {
  //
  const loadEnergyValues = reportData.map((data) =>
    loadPowerComsuption(
      data.core_system_link_voltage,
      data.metric_load_current_estimate
    )
  )
  //
  const gridEnergyValues = reportData.map((data) =>
    acEnergy(
      data.tel_status_rect_measured_voltage,
      data.tel_status_rect_measured_current
    )
  )
  //
  const CO2Values = reportData.map((data) =>
    CO2Emission(
      solarEnergyFunction(
        data.core_mppt_output_voltage,
        data.core_mppt_output_current
      )
    )
  )
  //
  const loadAvg = arrayAvg(loadEnergyValues) / 1000
  //
  const CO2Eq = CO2Values.reduce((a, b) => a + b, 0) / 1000
  // @ts-ignore
  const expectedGridUsage = siteData?.grid_percentage
  //
  const gridUsageReported =
    gridEnergyValues.reduce((a, b) => a + b, 0) /
    loadEnergyValues.reduce((a, b) => a + b, 0)
  //
  const gridAvailability = arrayAvg(
    reportData.map((data) => +data.info_grid_active_status)
  )
  //
  const batteryTemp = arrayAvg(
    reportData.map((data) => data.core_ess_brick_max_temp)
  )
  //
  const ambTemp = arrayAvg(
    reportData.map((data) => data.core_system_ambient_temp)
  )

  return {
    loadEnergyValues,
    gridEnergyValues,
    CO2Values,
    loadAvg,
    CO2Eq,
    expectedGridUsage,
    gridUsageReported,
    gridAvailability,
    batteryTemp,
    ambTemp,
  }
}

export const dataForLlaReportExport = (
  data: SiteReport,
  siteData: AsstMgmDevice,
  PRECISION_LENGHT: number
) => [
  data.date,
  data.time,
  // data.tel_status_rect_measured_voltage,
  // data.tel_status_rect_measured_current,
  data.core_mppt_output_voltage,
  data.core_system_link_voltage,
  data.core_ess_current,
  data.core_mppt_output_current,
  data.metric_load_current_estimate,
  `${+data.info_grid_active_status * 100}%`,
  data.core_ess_min_soc,
  data.core_ess_brick_max_temp,
  data.core_system_ambient_temp,
  (
    batteryEnergy(data.core_system_link_voltage, data.core_ess_current) / 1000
  ).toFixed(PRECISION_LENGHT),
  (
    acEnergy(
      data.tel_status_rect_measured_voltage,
      data.tel_status_rect_measured_current
    ) / 1000
  ).toFixed(PRECISION_LENGHT),
  (
    loadPowerComsuption(
      data.core_system_link_voltage,
      data.metric_load_current_estimate
    ) / 1000
  ).toFixed(PRECISION_LENGHT),
  solarEnergyFunction(
    data.core_mppt_output_voltage,
    data.core_mppt_output_current
  ).toFixed(PRECISION_LENGHT),
  excessEnergy(
    loadPowerComsuption(
      data.core_system_link_voltage,
      data.metric_load_current_estimate
    ),
    solarEnergyFunction(
      data.core_mppt_output_voltage,
      data.core_mppt_output_current
    )
  ).toFixed(PRECISION_LENGHT),
  siteData?.contracted_load,
  `${(
    powerChanges(
      loadPowerComsuption(
        data.core_system_link_voltage,
        data.metric_load_current_estimate
      ),
      siteData?.contracted_load ? siteData.contracted_load : 1
    ) * 100
  ).toFixed(2)}%`,
  CO2Emission(
    solarEnergyFunction(
      data.core_mppt_output_voltage,
      data.core_mppt_output_current
    )
  ).toFixed(PRECISION_LENGHT),
]
