import {
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  FilledInput,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { AsstMgmCountry, AsstMgmSiteList } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import ApiHandler from '../../../services/api/apiHandler'
import {
  getCountryInfoQueries,
  insertVisitQuery,
} from './VisitsFormPage.queries'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import PageComponent from '../../../components/PageComponents/PageComponent'
import {
  FormButton,
  FormContainer,
  FormSection,
} from '../../../components/FormComponents'

const WORKS_TO_DO = [
  'Implementation / Dissassembly',
  'Survey / Audit',
  'Preventive Maintenance',
  'Corrective Maintenace',
]

const VisitsFormPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { nocappUser } = useNocAppAuth()
  const [loadingProps, setLoadingProps] = useState(false)
  const [countries, setCountries] = useState<AsstMgmCountry[]>([])
  const [sites, setSites] = useState<AsstMgmSiteList[]>([])
  const [companies, setCompanies] = useState<{ company: string }[]>([])
  const [supervisors, setSupervisors] = useState<
    { supervisor: string; supervisor_phone: string; supervisor_id: string }[]
  >([])
  const [selectedSites, setSelectedSites] = useState<string[]>([])
  const [newCompany, setNewCompany] = useState(false)
  const [newSupervisor, setNewSupervisor] = useState(false)
  const [loading, setLoading] = useState(false)
  // Form controllers
  const [supervisorPhone, setSupervisorPhone] = useState('')
  const [supervisorId, setSupervisorId] = useState('')
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  const handleSelectedSiteChange = (
    event: SelectChangeEvent<typeof selectedSites>
  ) => {
    const {
      target: { value },
    } = event
    setSelectedSites(typeof value === 'string' ? value.split(',') : value)
  }

  // Get countries
  const getCountriesHandle = useCallback(async () => {
    setLoadingProps(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/countries`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const data = await countriesFetch.json()
    setCountries(data.message)
    setLoadingProps(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    getCountriesHandle()
  }, [getCountriesHandle])

  const getCountryInfoHandler = async (country: number) => {
    setLoadingProps(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const apiHandler = new ApiHandler()
    // Queries
    const countryName = countries.find((x) => x.id === country)?.name
    const queries = getCountryInfoQueries(countryName!)
    // Get
    const sitesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?country_id=${country}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const sitesData = await sitesFetch.json()
    const [companiesData, supervisorsData] = await Promise.all([
      apiHandler.getList(queries.companies),
      apiHandler.getList(queries.supervisors),
    ])
    setSites(sitesData.message)
    setCompanies(companiesData)
    setSupervisors(supervisorsData)
    setLoadingProps(false)
  }

  const sendVisit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    const apiHandler = new ApiHandler()
    // Transfrom data
    const body = apiHandler.transformFormDataToJson(event.currentTarget)
    body.user = nocappUser!.username
    body.name = nocappUser!.name
    body.email = nocappUser!.email
    body.phone = nocappUser!.mobile
    try {
      selectedSites.map(async (site) => {
        const query = insertVisitQuery(body, site)
        await apiHandler.postItem(query)
      })
      setSeverity('success')
      setMessage('Requested site visit')
      setOpenSnackbar(true)
    } catch (error) {
      setSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Visit form"
      scrollable
      loading={loadingProps}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={sendVisit}>
        {/* Site info */}
        <FormSection
          useBackButton
          title="Visit form"
          sections={[
            [
              <TextField
                select
                fullWidth
                label="Select a country"
                name="country"
                required
                onChange={(e) => getCountryInfoHandler(+e.target.value)}
              >
                <MenuItem value={-1} />
                {countries.map((country, i) => (
                  <MenuItem key={i} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>,
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>Select a site/sites</InputLabel>
                <Select
                  multiple
                  value={selectedSites}
                  required
                  onChange={handleSelectedSiteChange}
                  input={<FilledInput />}
                >
                  {sites.map((site, i) => (
                    <MenuItem key={i} value={site.site.code}>
                      {site.site.code} / {site.site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>,
            ],
          ]}
        />
        {/* Applicant Details */}
        <FormSection
          title="Applicant Details"
          sections={[
            [
              // New company
              newCompany ? (
                <TextField
                  fullWidth
                  label="Company"
                  required
                  name="company"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setNewCompany(!newCompany)
                          }}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Company"
                  required
                  name="company"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setNewCompany(!newCompany)
                          }}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {companies.map((company, i) => (
                    <MenuItem key={i} value={company.company}>
                      {company.company}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              // New supervisor
              newSupervisor ? (
                <TextField
                  fullWidth
                  label="Supervisor"
                  required
                  name="supervisor"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setNewSupervisor(!newSupervisor)
                          }}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Supervisor"
                  required
                  name="supervisor"
                  onChange={(e) => {
                    const sup = supervisors.find(
                      (el) => el.supervisor === e.target.value
                    )
                    setSupervisorPhone(sup!.supervisor_phone)
                    setSupervisorId(sup!.supervisor_id)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setNewSupervisor(!newSupervisor)
                          }}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {supervisors.map((supervisor, i) => (
                    <MenuItem key={i} value={supervisor.supervisor}>
                      {supervisor.supervisor}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
            [
              <TextField
                fullWidth
                label="Supervisor phone"
                name="supervisor_phone"
                value={supervisorPhone}
                onChange={(e) => setSupervisorPhone(e.target.value)}
              />,
              <TextField
                fullWidth
                label="Supervisor ID"
                name="supervisor_id"
                value={supervisorId}
                onChange={(e) => setSupervisorId(e.target.value)}
              />,
            ],
          ]}
        />
        {/* Work to do */}
        <FormSection
          title="Work to do"
          sections={[
            [
              <RadioGroup name="work">
                {WORKS_TO_DO.map((work, i) => (
                  <FormControlLabel
                    key={i}
                    value={work}
                    control={<Radio required value={work} name="work" />}
                    label={work}
                  />
                ))}
              </RadioGroup>,
            ],
            [
              <TextField
                fullWidth
                label="Activity details"
                name="activity_details"
                multiline
                rows={4}
              />,
            ],
            [
              <TextField
                fullWidth
                required
                label="Planned start date"
                type="datetime-local"
                focused
                name="start_datetime"
              />,
              <TextField
                fullWidth
                label="Planned end date"
                focused
                type="datetime-local"
                name="end_datetime"
              />,
            ],
          ]}
        />
        {/* Technitians */}
        <FormSection
          title="Technicians"
          sections={[
            [
              <TextField fullWidth label="Name" name="technician_1" />,
              <TextField fullWidth label="Technician ID" name="id_1" />,
            ],
            [
              <TextField fullWidth label="Name" name="technician_2" />,
              <TextField fullWidth label="Technician ID" name="id_2" />,
            ],
            [
              <TextField fullWidth label="Name" name="technician_3" />,
              <TextField fullWidth label="Technician ID" name="id_3" />,
            ],
            [
              <TextField fullWidth label="Name" name="technician_4" />,
              <TextField fullWidth label="Technician ID" name="id_4" />,
            ],
            [
              <TextField fullWidth label="Name" name="technician_5" />,
              <TextField fullWidth label="Technician ID" name="id_5" />,
            ],
          ]}
        />
        {/* Send */}
        <FormButton loading={loading} title="Request visit" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </PageComponent>
  )
}

export default VisitsFormPage
