import {
  DEVICES_IDS_DB,
  DEVICES_INFO_DB,
  SITES_DATES_DB,
  SITES_DB,
  SITES_INFO_DB,
  SITES_LOCATION_DB,
} from '../../../services/api/databases'

const SITE_JOINS_PARAMS = `
  LEFT JOIN ${SITES_INFO_DB} ON ${SITES_INFO_DB}.site_id = ${SITES_DB}.caban_id
  LEFT JOIN ${SITES_LOCATION_DB} ON ${SITES_LOCATION_DB}.site_id = ${SITES_DB}.caban_id
  LEFT JOIN ${SITES_DATES_DB} ON ${SITES_DATES_DB}.site_id = ${SITES_DB}.caban_id
  LEFT JOIN ${DEVICES_INFO_DB} ON ${DEVICES_INFO_DB}.site_id = ${SITES_DB}.caban_id
  LEFT JOIN ${DEVICES_IDS_DB} ON ${DEVICES_IDS_DB}.site_id = ${SITES_DB}.caban_id
`

export const activeAndInactiveSitesHandle = async (token: string) => {
  const activeSitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?active=true`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const inactiveSitesFetch = await fetch(
    `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/list?active=false`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
  const activeSitesData = await activeSitesFetch.json()
  const inactiveSitesData = await inactiveSitesFetch.json()

  return {
    activeSitesData: activeSitesData.message,
    inactiveSitesData: inactiveSitesData.message,
  }
}

export const activeAndInactiveSitesQueries = () => {
  return {
    active: `SELECT
      *
    FROM ${SITES_DB}
      ${SITE_JOINS_PARAMS}
    WHERE
      ${SITES_DB}.active = 1`,
    //
    inactive: `SELECT
      *
    FROM ${SITES_DB}
      ${SITE_JOINS_PARAMS}
    WHERE
      ${SITES_DB}.active = 0`,
    //
    activeByCountry: `SELECT DISTINCT country, COUNT(caban_id) as count FROM ${SITES_DB} WHERE active = 1 GROUP BY country ORDER BY country`,
    inactiveByCountry: `SELECT DISTINCT country, COUNT(caban_id) as count FROM ${SITES_DB} WHERE active = 0 GROUP BY country ORDER BY country`,
  }
}
