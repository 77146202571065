interface ApiBody {
  sql: string
  responseType: 'array' | 'object' | 'status' | 'inserted-id'
}

interface ParameterizedApiBody {
  sql: string
  response_type: 'array' | 'object' | 'status' | 'inserted-id'
  params: string[] | number[]
}

export interface MailServiceBody {
  destination: string
  subject: string
  body: string
  headers: string
}

export const SERVER_DUMP_LINK = '/_Caban/app/'

class ApiHandler {
  async executeQuery(body: ApiBody) {
    return new Promise(async (res, rej) => {
      const serverRes = await fetch('/api', {
        method: 'post',
        body: JSON.stringify(body),
      })
      const data = await serverRes.json()

      if (data.status === 'fail' || data.status === 'error') {
        rej(new Error('There has been an error'))
      } else {
        res(data)
      }
    })
  }

  /**
   *
   * @param body
   * @returns
   */
  async executeParameterizedQuery(body: ParameterizedApiBody) {
    return new Promise(async (res, rej) => {
      const serverRes = await fetch('/api/parameterized', {
        method: 'post',
        body: JSON.stringify(body),
      })
      const data = await serverRes.json()

      if (data.status === 'fail' || data.status === 'error') {
        rej(new Error('There has been an error'))
      } else {
        res(data)
      }
    })
  }

  transformFormDataToJson(data: HTMLFormElement): any {
    const formData = new FormData(data)
    const body: any = {}
    formData.forEach(
      // eslint-disable-next-line no-return-assign
      (value, key) => (body[key] = value !== '' ? `'${value}'` : 'NULL')
    )
    return body
  }

  transformFormDataToJsonWithNull(data: HTMLFormElement): any {
    const formData = new FormData(data)
    const body: any = {}
    formData.forEach(
      // eslint-disable-next-line no-return-assign
      (value, key) => (body[key] = value !== '' ? `${value}` : null)
    )
    return body
  }

  transformParametizedFormDataToJson(data: HTMLFormElement): any {
    const formData = new FormData(data)
    const body: any = {}
    formData.forEach(
      // eslint-disable-next-line no-return-assign
      (value, key) => (body[key] = value !== '' ? `${value}` : 'NULL')
    )
    return body
  }

  async getList(query: string): Promise<any[]> {
    const body: ApiBody = {
      sql: query,
      responseType: 'array',
    }
    const res = await this.executeQuery(body)
    // @ts-ignore
    return res
  }

  /**
   * @param sql Parameterized sql query
   * @param params Array of values
   */
  async getParameterizedList(
    sql: string,
    params: ParameterizedApiBody['params']
  ): Promise<any[]> {
    const body: ParameterizedApiBody = {
      sql,
      response_type: 'array',
      params,
    }
    const res = await this.executeParameterizedQuery(body)
    // @ts-ignore
    return res
  }

  async getObject(query: string): Promise<any> {
    const body: ApiBody = {
      sql: query,
      responseType: 'object',
    }
    const res = await this.executeQuery(body)
    // @ts-ignore
    return res
  }

  /**
   * @param sql Parameterized sql query
   * @param params Array of values
   */
  async getParameterizedObject(
    sql: string,
    params: ParameterizedApiBody['params']
  ): Promise<any> {
    const body: ParameterizedApiBody = {
      sql,
      response_type: 'object',
      params,
    }
    const res = await this.executeParameterizedQuery(body)
    // @ts-ignore
    return res
  }

  async postTo(body: any, uri: string): Promise<any> {
    return new Promise(async (res, rej) => {
      const serverRes = await fetch(uri, {
        method: 'post',
        body: JSON.stringify(body),
      })
      const data = await serverRes.json()

      if (data.status === 'fail') {
        rej(new Error('There has been an error'))
      } else {
        res(data)
      }
    })
  }

  /**
   * @param sql Parameterized sql query
   * @param params Array of values
   */
  async postParameterizedItem(
    sql: string,
    params: ParameterizedApiBody['params']
  ): Promise<{ status: 'success' | 'fail' }> {
    const body: ParameterizedApiBody = {
      sql,
      response_type: 'status',
      params,
    }
    const res = await this.executeParameterizedQuery(body)
    // @ts-ignore
    return res
  }

  async postItem(query: string): Promise<{ status: 'success' | 'fail' }> {
    const body: ApiBody = {
      sql: query,
      responseType: 'status',
    }
    const res = await this.executeQuery(body)
    // @ts-ignore
    return res
  }

  async insertAndGetId(query: string): Promise<{ id: number }> {
    const body: ApiBody = {
      sql: query,
      responseType: 'inserted-id',
    }
    const res = await this.executeQuery(body)
    // @ts-ignore
    return res
  }

  /**
   * @param sql Parameterized sql query
   * @param params Array of values
   */
  async insertParameterizedAndGetId(
    sql: string,
    params: ParameterizedApiBody['params']
  ): Promise<{ id: number }> {
    const body: ParameterizedApiBody = {
      sql,
      response_type: 'inserted-id',
      params,
    }
    const res = await this.executeParameterizedQuery(body)
    // @ts-ignore
    return res
  }

  async uploadFile(body: FormData, uri: string) {
    return new Promise(async (res, rej) => {
      const serverRes = await fetch(uri, {
        method: 'post',
        body,
      })
      const data = await serverRes.json()

      if (data.status === 'fail') {
        rej(new Error('There has been an error'))
      } else {
        res(data)
      }
    })
  }

  async sendMail(body: MailServiceBody) {
    return new Promise(async (res, rej) => {
      const serverRes = await fetch('api/mail-service', {
        method: 'post',
        body: JSON.stringify(body),
      })
      const data = await serverRes.json()

      if (data.error) {
        rej(new Error('There has been an error'))
      } else {
        res(data)
      }
    })
  }
}

export default ApiHandler
