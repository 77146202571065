import { useParams, Link, useNavigate } from 'react-router-dom'
import { AsstMgmSiteList } from 'nocapp-types'
import { IconButton, styled, Tooltip } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { EditRounded as EditRoundedIcon } from '@mui/icons-material'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'
import LoadingPage from '../../../components/PageComponents/LoadingPage'
import grafanaLogo from '../../../assets/img/grafana-logo.svg'
import staticMap1 from '../../../assets/img/staticmap.png'
import { siteInfoHandle } from './SiteInfoPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'
import GridCard from '../../../components/PageComponents/GridCard'
import ReturnButton from '../../../components/PageComponents/ReturnButton'
import { MONTHS_LIST } from '../../../utils/datesHandle'

interface SiteCoordsBoxProps {
  img: string
}

const SiteInfoPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { nocappUser } = useNocAppAuth()
  const navigate = useNavigate()
  const { cabanId } = useParams<'cabanId'>()
  const [site, setSite] = useState<AsstMgmSiteList>()
  const [loading, setLoading] = useState(false)

  const getSiteInfo = useCallback(
    async (id: string) => {
      setLoading(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })
      // Get
      const siteData = await siteInfoHandle(id, token)
      //
      setSite(siteData[0])
      setLoading(false)
    },
    [getAccessTokenSilently]
  )

  useEffect(() => {
    // Get site info
    getSiteInfo(cabanId!)
  }, [cabanId, getSiteInfo, nocappUser])

  if (!site) {
    return <LoadingPage />
  }

  const formatDate = (string: string) => {
    const date = new Date(string)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    return `${
      MONTHS_LIST[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`
  }

  const siteInfoData = [
    {
      title: 'Caban ID',
      value: site!.site.code,
    },
    {
      title: 'Modem ID',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].id
          : '',
    },
    { title: 'Site Name', value: site!.site.name },
    {
      title: 'Country',
      value:
        site!.site.location && site!.site.location.country
          ? site!.site.location.country.name
          : '',
    },
    {
      title: 'Active',
      value: site!.site.active ? 'Yes' : 'No',
    },
    {
      title: 'Last update on',
      value: site.site.updated_date,
    },
  ]

  const siteLocationData = [
    {
      title: 'Address',
      value: site.site!.location ? site.site!.location.address1 : '',
    },
    {
      title: 'Zone',
      value:
        site.site!.location && site.site.location.zone
          ? site.site!.location.zone.name
          : '',
    },
  ]

  const siteInfo2Data = [
    {
      title: 'Contracted load (kW)',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].contracted_load
          : '',
    },
    { title: 'Type', value: site!.site.type },
    {
      title: 'Customer',
      value: site!.site.customer ? site!.site.customer.name : '',
    },
    {
      title: 'Customer ID',
      value: site!.site.customer_code,
    },
    { title: 'Owner', value: site!.site.owner ? site!.site.owner.name : '' },
    { title: 'Owner ID', value: site!.site.owner_code },
    {
      title: 'Supervisor',
      value: site!.site.supervisor ? site!.site.supervisor.name : '',
    },
    {
      title: 'See site alerts',
      link: true,
      value: `/sites/info/${site.site.id}/alerts`,
    },
  ]

  const devicesInfoData = [
    {
      title: 'Genset',
      value:
        site!.site.devices &&
        site!.site.devices.length > 0 &&
        site!.site.devices[0].genset_present
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Grid',
      value:
        site!.site.devices &&
        site!.site.devices.length > 0 &&
        site!.site.devices[0].grid_present
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Grid percentage',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].grid_percentage
          : '',
    },
    {
      title: 'Solar panels',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].solar_panel_count
          : '',
    },
    {
      title: 'Solar structures',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].solar_structures
          : '',
    },
    {
      title: 'Packs',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].pack_count
          : '',
    },
    {
      title: 'MPTT',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].mppt_count
          : '',
    },
    {
      title: 'Rectifiers',
      value:
        site!.site.devices && site!.site.devices.length > 0
          ? site!.site.devices[0].rectifiers
          : '',
    },
  ]

  const devicesIdsData = [
    {
      title: 'Cabinet SN',
      value:
        site.site.devices &&
        site.site.devices!.length > 0 &&
        site.site.devices![0].cabinet
          ? site.site.devices![0].cabinet?.name
          : '',
    },
    {
      title: 'Configuration',
      value:
        site.site.devices && site.site.devices?.length > 0
          ? site.site.devices[0].configuration
          : '',
    },
    {
      title: 'Cabinet type',
      value:
        site.site.devices &&
        site.site.devices?.length > 0 &&
        site.site.devices[0].cabinet
          ? site.site.devices[0].cabinet?.type
          : '',
    },
    {
      title: 'Battery model',
      value:
        site.site.devices &&
        site.site.devices?.length > 0 &&
        site.site.devices[0].battery
          ? site.site.devices[0].battery?.name
          : '',
    },
  ]

  const sitesDatesData = [
    {
      title: 'Turn on date',
      value:
        site.site.devices &&
        site.site.devices.length > 0 &&
        site!.site.devices[0].turn_on_date
          ? formatDate(site!.site.devices[0].turn_on_date)
          : '',
    },
    {
      title: 'Alerts activation',
      value:
        site.site.devices &&
        site.site.devices.length > 0 &&
        site!.site.devices[0].alerts_activation_date
          ? formatDate(site!.site.devices[0].alerts_activation_date)
          : '',
    },
    {
      title: 'Migration date',
      value:
        site.site.devices &&
        site.site.devices.length > 0 &&
        site!.site.devices[0].migration_date
          ? formatDate(site!.site.devices[0].migration_date)
          : '',
    },
    {
      title: 'Commencement date',
      value: site.site.commencement_date
        ? formatDate(site.site.commencement_date)
        : '',
    },
  ]

  const siteLinksData = [
    {
      title: 'Site information link',
      value:
        site.site.devices && site.site.devices?.length > 0
          ? site.site.devices[0].reports_url
          : '',
    },
  ]

  const grafanaAlertsLinks = [
    {
      title: 'Custom dashboard',
      link: `https://grafana.cabanenergy.com/d/LyY2GId4k/custom-dashboard?orgId=1&var-device_id=${
        site!.site!.devices![0]!.id
      }`,
    },
    {
      title: 'Dashboard (hourly)',
      link: `https://grafana.cabanenergy.com/d/8X1hMSO4k/custom-dashboard-hourly?orgId=1&var-device_id=${
        site!.site.devices![0]!.id
      }&var-custom_1=core_ess_brick_max_temp&var-custom_1=core_ess_current&var-custom_1=core_ess_max_soc&var-custom_1=core_mppt_output_current&var-custom_1=core_mppt_output_voltage&var-custom_1=core_system_ambient_temp&var-custom_1=core_system_link_voltage&var-custom_1=info_grid_active_status&var-custom_1=metric_load_current_estimate&var-custom_1=tel_status_rect_measured_current&var-custom_1=tel_status_rect_measured_voltage&var-custom_2=core_ess_brick_min_voltage`,
    },
    {
      title: 'Device insights',
      link: `https://grafana.cabanenergy.com/d/AIx2MIdVz/device-insights-v2?orgId=1&var-device_id=${
        site!.site.devices![0]!.id
      }`,
    },
    {
      title: 'History dashboard',
      link: `https://grafana.cabanenergy.com/d/vQahMSd4k/historical-dashboard-v2?orgId=1&var-device_id=${
        site!.site.devices![0]!.id
      }`,
    },
  ]

  // Conditional data
  // const distanceData = [
  //   { title: 'Distance to site (km)', value: site.distance },
  //   { title: 'Travel time', value: site.travel_time },
  // ]

  const severityData = [
    {
      title: 'Severity',
      value: site.site.severity ? site.site.severity.name : '',
    },
    {
      title: 'Max. repair time with total loss of service',
      value: site.site.severity
        ? site.site.severity.max_repair_time_total_loss
        : '',
    },
    {
      title: 'Max. repair time with partial loss of service',
      value: site.site.severity
        ? site.site.severity.max_repair_time_partial_loss
        : '',
    },
    {
      title: 'Max. repair time without service affection',
      value: site.site.severity
        ? site.site.severity.max_repair_time_without_service_affection
        : '',
    },
  ]

  return (
    <PageComponent loading={loading} centerComponent={<SitesSearchBar />}>
      <GridContainer>
        <SiteNameEditContainer>
          <SiteNameContainer>
            {site!.site.name} | {site!.site.code}
          </SiteNameContainer>
          <SiteEditReturnContainer>
            <ReturnButton />
            {(nocappUser!.profile === 'admin' ||
              nocappUser!.profile === 'noc') && (
              <IconButton onClick={() => navigate(`update`)}>
                <EditRoundedIcon />
              </IconButton>
            )}
          </SiteEditReturnContainer>
        </SiteNameEditContainer>
        {/* Info */}
        <SiteDataContainer>
          {siteInfoData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Info 2 */}
        <SiteDataContainer>
          {siteInfo2Data.map((data, i) =>
            data.link ? (
              <LinkBox to={data.value} key={i}>
                <InfoValue />
                <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
              </LinkBox>
            ) : (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            )
          )}
        </SiteDataContainer>
        {/* Location */}
        <SiteDataContainer>
          <Tooltip title="Not real image location">
            <SiteCoordsBackdrop
              img={staticMap1}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps?q=${
                site.site.location ? site.site.location.latitude : ''
              },${site.site.location ? site.site.location.longitude : ''}&t=k`}
            >
              <SiteCoordsContainer>
                <span>
                  Site coords:{' '}
                  {site.site.location ? site.site.location.latitude : ''},{' '}
                  {site.site.location ? site.site.location.longitude : ''}
                </span>
              </SiteCoordsContainer>
            </SiteCoordsBackdrop>
          </Tooltip>
          {siteLocationData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Devices info */}
        <SiteDataContainer>
          {devicesInfoData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Devices ids */}
        <SiteDataContainer>
          {devicesIdsData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Sites dates */}
        <SiteDataContainer>
          {sitesDatesData.map((data, i) => (
            <InfoBox key={i}>
              <InfoValue>{data.value}</InfoValue>
              <InfoTitle>{data.title}</InfoTitle>
            </InfoBox>
          ))}
        </SiteDataContainer>
        {/* Sites links */}
        <SiteDataContainer style={{ gridTemplateColumns: '1fr' }}>
          {siteLinksData.map((data, i) => (
            <ExternalLinkBox
              target="_blank"
              rel="noopener noreferrer"
              href={data.value}
              key={i}
            >
              <InfoValue />
              <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
            </ExternalLinkBox>
          ))}
        </SiteDataContainer>
        {/* Grafana links */}
        <SiteDataContainer>
          {grafanaAlertsLinks.map((data, i) => (
            <GrafanaBox
              target="_blank"
              rel="noopener noreferrer"
              href={data.link}
              key={i}
            >
              <InfoValue>
                <GrafanaLogo src={grafanaLogo} alt="" />
              </InfoValue>
              <InfoTitle style={{ color: 'white' }}>{data.title}</InfoTitle>
            </GrafanaBox>
          ))}
        </SiteDataContainer>
        <SiteDataContainer style={{ gridTemplateColumns: '1fr' }}>
          <InfoBox>
            <InfoValue>{site.site.comments}</InfoValue>
            <InfoTitle>Commentaries</InfoTitle>
          </InfoBox>
        </SiteDataContainer>
        {/* Distance time */}
        {/* {site.distance !== null && (
          <SiteDataContainer>
            {distanceData.map((data, i) => (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            ))}
          </SiteDataContainer>
        )} */}
        {/* Severity */}
        {site.site.severity !== null && (
          <SiteDataContainer>
            {severityData.map((data, i) => (
              <InfoBox key={i}>
                <InfoValue>{data.value}</InfoValue>
                <InfoTitle>{data.title}</InfoTitle>
              </InfoBox>
            ))}
          </SiteDataContainer>
        )}
      </GridContainer>
    </PageComponent>
  )
}

const GridContainer = styled('div')(() => ({
  width: '100%',
  display: 'block',
  columns: '300px',
  gap: GLOBAL_PAGE_GAP,
}))
const SiteNameEditContainer = styled(GridCard)(() => ({
  background: 'inherit',
  display: 'grid',
  height: 'fit-content',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  fontWeight: 'bold',
  fontSize: 18,
  marginBottom: GLOBAL_PAGE_GAP,
}))
const SiteNameContainer = styled(GridCard)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))
const SiteEditReturnContainer = styled(GridCard)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const SiteDataContainer = styled(GridCard)(() => ({
  display: 'grid',
  height: 'fit-content',
  gridTemplateColumns: 'repeat(2, 1fr)',
  marginBottom: GLOBAL_PAGE_GAP,
  breakInside: 'avoid',
  gap: 10,
}))
const InfoBox = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
}))
const SiteCoordsBackdrop = styled('a')<SiteCoordsBoxProps>(({ img }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  height: 130,
  color: 'white',
  backgroundImage: `url(${img})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  borderRadius: 7,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 'bold',
}))
const SiteCoordsContainer = styled('div')({
  flex: 1,
  padding: 7,
  borderRadius: 7,
  background: `linear-gradient(
    to bottom,
    transparent 40%,
    #00000081 90%)`,
  display: 'flex',
  alignItems: 'flex-end',
  transition: '300ms ease-in-out',
})
const LinkBox = styled(Link)(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const ExternalLinkBox = styled('a')(({ theme }) => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const GrafanaBox = styled('a')(() => ({
  width: '100%',
  minHeight: 100,
  borderRadius: 7,
  padding: 7,
  background: 'rgb(24, 27, 31)',
  fontSize: 14,
  display: 'flex',
  flexDirection: 'column',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))
const GrafanaLogo = styled('img')({
  width: 25,
  height: 25,
})
const InfoValue = styled('div')(() => ({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  textAlign: 'center',
}))
const InfoTitle = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  fontWeight: 'bold',
  fontSize: 14,
  color: theme.palette.text.secondary,
}))

export default SiteInfoPage
