import { styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import {
  AsstMgmCountry,
  AsstMgmSiteDashboard,
  AsstMgmSiteList,
} from 'nocapp-types'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import SitesList from '../../../components/Sites/SitesList'
import CountryDivisions from '../../../components/Sites/CountryDivisions'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'
import { sitesByCountryFetch, sitesIndexFetch } from './SitesIndexPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import ReturnButton from '../../../components/PageComponents/ReturnButton'
import GridCard from '../../../components/PageComponents/GridCard'
import { GLOBAL_PAGE_GAP } from '../../../components/PageComponents/constants'

const SitesIndexPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { nocappUser } = useNocAppAuth()
  const navigate = useNavigate()
  const [sites, setSites] = useState<AsstMgmSiteList[]>([])
  const [countries, setCountries] = useState<AsstMgmCountry[]>([])
  const [addSite, setAddSite] = useState(true)
  const [siteDashboard, setSiteDashboard] =
    useState<AsstMgmSiteDashboard | null>(null)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })

    const { countriesData, dashboard, sitesData } = await sitesIndexFetch(token)
    //
    setSites(sitesData)
    setCountries(countriesData)
    setSiteDashboard(dashboard)
    setLoading(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (nocappUser!.profile !== 'admin' && nocappUser!.profile !== 'noc') {
      setAddSite(false)
    }
    getData()
  }, [getData, nocappUser])

  const countryFilterHandler = useCallback(
    async (country) => {
      setLoading(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })
      const data = await sitesByCountryFetch(token, country)
      //
      setSites(data)
      setSelectedCountry(country)
      setLoading(false)
    },
    [getAccessTokenSilently]
  )

  return (
    <PageComponent
      title="Sites"
      loading={loading}
      centerComponent={<SitesSearchBar />}
    >
      <GridContainer>
        <ReturnContainer hoveranimation>
          <ReturnButton />
        </ReturnContainer>
        {addSite ? (
          <CreateNewSiteContainer
            onClick={() => navigate('create-site')}
            hoveranimation
          >
            <div
              style={{
                flex: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AddRoundedIcon fontSize="large" />
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
              Add new site
            </div>
          </CreateNewSiteContainer>
        ) : (
          <GridCard />
        )}
        <TotalOfSitesContainer onClick={() => navigate('all')} hoveranimation>
          <div
            style={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 40 }}>
              {sites.length}
            </span>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
            <span style={{ fontWeight: 'bold' }}>Total of active sites</span>
          </div>
        </TotalOfSitesContainer>
        <CountriesDivisionContainer hoveranimation>
          <CountryDivisions countries={siteDashboard?.countries || []} />
        </CountriesDivisionContainer>
        {/*  */}
        <SitesListContainer>
          <SitesList
            sites={sites}
            countries={countries}
            countryFilterHandler={countryFilterHandler}
            selectedCountry={selectedCountry}
          />
        </SitesListContainer>
      </GridContainer>
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridTemplateRows: 'repeat(6, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))
const ReturnContainer = styled(GridCard)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const TotalOfSitesContainer = styled(GridCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    gridRowStart: 2,
  },
}))
const CountriesDivisionContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 3,
  gridRowEnd: 7,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const SitesListContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 3,
  gridColumnEnd: 7,
  gridRowStart: 1,
  gridRowEnd: 7,
  [theme.breakpoints.down('sm')]: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 3,
  },
}))
const CreateNewSiteContainer = styled(GridCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: '200ms ease-in-out',
  ':hover': {
    opacity: 0.9,
  },
}))

export default SitesIndexPage
