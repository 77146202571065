/* eslint-disable no-param-reassign */
export const getDevicesFetch = async (token: string) => {
  const res = await fetch(`${process.env.REACT_APP_ASSET_MGM_URL}/devices`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  const data = (await res.json()).message
  return data
}

export const getReportInfoFromSiteFetch = async (
  id: number,
  start: number,
  end: number,
  token: string
) => {
  const res = await fetch(`${process.env.REACT_APP_TELEMETRY_URL}/telemetry`, {
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({
      metrics: [
        'core_ess_brick_max_temp',
        'core_ess_current',
        'core_ess_min_soc',
        'core_mppt_output_current',
        'core_mppt_output_voltage',
        'core_system_ambient_temp',
        'core_system_link_voltage',
        'info_grid_active_status',
        'metric_load_current_estimate',
        'tel_status_rect_measured_current',
        'tel_status_rect_measured_voltage',
      ],
      aggregate: 'avg',
      device_ids: [id],
      interval: '1h',
      ts_start: start,
      ts_end: end,
    }),
  })
  const data = (await res.json()).message
  return data.map((row: any) => {
    const timestamp = new Date(row.timestamp)
    timestamp.setMinutes(timestamp.getMinutes() - timestamp.getTimezoneOffset())
    Object.keys(row).map((key) => {
      if (typeof row[key] === 'number') {
        row[key] = row[key].toFixed(2)
      }
      return ''
    })

    return {
      date: `${timestamp.getFullYear()}-${
        timestamp.getMonth() + 1 < 10
          ? `0${timestamp.getMonth() + 1}`
          : timestamp.getMonth() + 1
      }-${
        timestamp.getDate() < 10
          ? `0${timestamp.getDate()}`
          : timestamp.getDate()
      }`,
      time: `${timestamp.getHours()}:${
        timestamp.getMinutes() < 10
          ? `0${timestamp.getMinutes()}`
          : timestamp.getMinutes()
      }:${
        timestamp.getSeconds() < 10
          ? `0${timestamp.getSeconds()}`
          : timestamp.getSeconds()
      }`,
      ...row,
    }
  })
}
