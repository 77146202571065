import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Alert, AsstMgmSiteList, Visit } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import { useNocAppAuth } from '../../services/auth/NocAppAuthContext'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import TodayVisitsListItem from '../../components/Dashboard/TodayVisitsList'
import ALertsToday from '../../components/Dashboard/AlertsToday'
import { useInterval } from '../../hooks/useInterval'
import { dashboardFetch, dashboardQueries } from './DashboardPage.queries'
import ApiHandler from '../../services/api/apiHandler'
import PageComponent from '../../components/PageComponents/PageComponent'
import Sidebar from '../../components/Dashboard/Sidebar'
import { GLOBAL_PAGE_GAP } from '../../components/PageComponents/constants'
import GridCard from '../../components/PageComponents/GridCard'
import { useKeyPress } from '../../hooks/useKeyPress'

interface SideBarContainerProps {
  visible?: boolean
}

const DashboardPage = () => {
  // Context
  const { getAccessTokenSilently } = useAuth0()
  const { nocappUser } = useNocAppAuth()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  // States
  const [menuIconForSidebar, setUseMenuIcon] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [lastSevenDaysAlertsCount, setLastSevenDaysAlertsCount] = useState(0)
  const [lastSevenAlerts, setLastSevenAlerts] = useState<Alert[]>([])
  const [visitsTodayCount, setVisitsTodayCount] = useState<Visit[]>([])
  const [totalOfSites, setTotalOfSites] = useState<AsstMgmSiteList[]>([])

  const totalBoxesValues = [
    {
      title: 'Last 7 days alerts count',
      link: '/alerts',
      value: lastSevenDaysAlertsCount,
    },
    {
      title: 'Planned visits today',
      link: '/visits',
      value: visitsTodayCount.length,
    },
    {
      title: 'Total of active sites',
      link: '/sites',
      value: totalOfSites.length,
    },
  ]

  useEffect(() => {
    if (width <= 600) {
      setUseMenuIcon(true)
    } else {
      setUseMenuIcon(false)
    }
  }, [width])

  // Close sidebar with escape key
  useKeyPress(() => {
    setOpenSidebar(false)
  }, 27)

  const getData = useCallback(async () => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    // Get queries
    const queries = dashboardQueries(nocappUser!.profile, nocappUser!.country)
    // Data
    const apiHandler = new ApiHandler()
    const { sites } = await dashboardFetch(token)
    const [lastSevenDaysAlertsCountData, alerts, visits] = await Promise.all([
      apiHandler.getObject(queries.lastSevenDaysAlertsCount),
      apiHandler.getList(queries.lastSevenAlerts),
      apiHandler.getList(queries.visits),
    ])
    // Set values
    setLastSevenDaysAlertsCount(lastSevenDaysAlertsCountData.count)
    setLastSevenAlerts(alerts)
    setVisitsTodayCount(visits)
    setTotalOfSites(sites)
    setLoading(false)
  }, [nocappUser, getAccessTokenSilently])

  useEffect(() => {
    getData()
  }, [getData])

  useInterval(() => {
    getData()
  }, 60000 * 50)

  return (
    <PageComponent
      loading={loading}
      menuForSidebar={menuIconForSidebar}
      openSidebar={openSidebar}
      setOpenSidebar={setOpenSidebar}
    >
      <GridContainer>
        <SidebarContainer visible={openSidebar}>
          <Sidebar />
        </SidebarContainer>
        {totalBoxesValues.map((item, i) => (
          <TotalValueBox
            onClick={() => navigate(item.link)}
            key={i}
            hoveranimation
          >
            <TotalValue>{item.value}</TotalValue>
            <TotalTitle>{item.title}</TotalTitle>
          </TotalValueBox>
        ))}
        <LastVisitsContainer hoveranimation onClick={() => navigate('/visits')}>
          <LastVisitContainerTitle>Last Visits</LastVisitContainerTitle>
          <LastVisitsContainerBody>
            {visitsTodayCount.length === 0 ? (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No planned visits
              </div>
            ) : (
              visitsTodayCount.map((visit, i) => (
                <TodayVisitsListItem key={i} visit={visit} />
              ))
            )}
          </LastVisitsContainerBody>
        </LastVisitsContainer>
        <LastAlertsContainer hoveranimation onClick={() => navigate('/alerts')}>
          <LastVisitContainerTitle>Last Entered Alerts</LastVisitContainerTitle>
          <LastVisitsContainerBody>
            {lastSevenAlerts.length === 0 ? (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No alerts
              </div>
            ) : (
              lastSevenAlerts
                .slice(0, 6)
                .map((alert, i) => <ALertsToday key={i} alert={alert} />)
            )}
          </LastVisitsContainerBody>
        </LastAlertsContainer>
      </GridContainer>
    </PageComponent>
  )
}

const GridContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(5, 1fr)',
  gap: GLOBAL_PAGE_GAP,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}))
const SidebarContainer = styled(GridCard)<SideBarContainerProps>(
  ({ theme, visible }) => ({
    gridRowStart: 1,
    gridRowEnd: 6,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 250,
      position: 'fixed',
      transform: `translateX(${visible ? '0px' : '-270px'})`,
    },
  })
)
const TotalValueBox = styled(GridCard)({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
})
const TotalValue = styled('div')({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
  fontWeight: 'bold',
})
const TotalTitle = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}))
const LastVisitsContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 2,
  gridColumnEnd: 5,
  gridRowStart: 2,
  gridRowEnd: 4,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    gridColumnStart: 1,
  },
}))
const LastAlertsContainer = styled(GridCard)(({ theme }) => ({
  gridColumnStart: 2,
  gridColumnEnd: 5,
  gridRowStart: 4,
  gridRowEnd: 6,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    gridColumnStart: 1,
  },
}))
const LastVisitContainerTitle = styled('span')({
  width: '100%',
  fontSize: 20,
  fontWeight: 'bold',
})
const LastVisitsContainerBody = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  paddingTop: 10,
})

export default DashboardPage
