import {
  InputAdornment,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AsstMgmCountry, AsstMgmSiteList } from 'nocapp-types'
import { useNocAppAuth } from '../../../services/auth/NocAppAuthContext'
import TableListPaginationActions from '../../PageComponents/TableListPaginationActions'

interface SitesShortListProps {
  sites: AsstMgmSiteList[]
  countries: AsstMgmCountry[]
  selectedCountry: string
  countryFilterHandler: Function
}

const SitesList = ({
  sites,
  countries,
  countryFilterHandler,
  selectedCountry,
}: SitesShortListProps) => {
  const theme = useTheme()
  const { nocappUser } = useNocAppAuth()
  const navigate = useNavigate()
  const [filterByCountry, setFilterByCountry] = useState(true)
  const [filteredSites, setFilteredSites] = useState<AsstMgmSiteList[]>([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    if (
      nocappUser!.profile === 'oym' ||
      nocappUser!.profile === 'contractor-int'
    ) {
      setFilterByCountry(false)
    }
    setFilteredSites(sites)
  }, [sites, nocappUser])

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchSiteHandler = useCallback(
    (value: string) => {
      function compareObjects(o1: any, o2: any) {
        let k = ''
        for (k in o1) if (o1[k] !== o2[k]) return false
        for (k in o2) if (o1[k] !== o2[k]) return false
        return true
      }

      function itemExists(haystack: any, needle: any) {
        for (let i = 0; i < haystack.length; i++)
          if (compareObjects(haystack[i], needle)) return true
        return false
      }

      const results: AsstMgmSiteList[] = []
      sites.forEach((siteInfo) => {
        const { site } = siteInfo
        for (const key in site) {
          if (Object.prototype.hasOwnProperty.call(site, key)) {
            // @ts-ignore
            const element: string =
              // @ts-ignore
              site[key] == null ? '' : site[key].toString()
            const formatedElement = element
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            const formatedValue = value
              .toLowerCase()
              .replaceAll(' ', '')
              .replaceAll('_', '')
              .replaceAll('-', '')
            if (formatedElement.search(formatedValue) !== -1) {
              if (
                !itemExists(
                  results.map((st) => st.site),
                  site
                )
              ) {
                results.push({ site })
              }
            }
          }
        }
      })
      setSearch(value)
      setFilteredSites(results)
    },
    [sites]
  )

  return (
    <Container>
      <SearchContainer>
        <div style={{ flex: 2 }}>
          <TextField
            fullWidth
            label="Search site"
            value={search}
            onChange={(e) => searchSiteHandler(e.target.value)}
            placeholder="ID, name, country, dates, etc."
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                borderRadius: 10,
              },
            }}
          />
        </div>
        <div
          style={{
            flex: 0.2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          {filteredSites.length} sites
        </div>
      </SearchContainer>
      <TableBoxContainer>
        <TableContainer
          style={{ width: '100%', height: '100%', display: 'flex' }}
        >
          <Table
            stickyHeader
            style={{ width: '100%', height: '100%' }}
            size="small"
          >
            <TableHead>
              <TableRow
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                <TableTitleCell>Caban ID</TableTitleCell>
                <TableTitleCell>Modem ID</TableTitleCell>
                <TableTitleCell>Site name</TableTitleCell>
                <TableTitleCell>Country</TableTitleCell>
                <TableTitleCell>Customer</TableTitleCell>
                <TableTitleCell>Supervisor</TableTitleCell>
                <TableTitleCell>Panels</TableTitleCell>
                <TableTitleCell>Structures</TableTitleCell>
                <TableTitleCell>MPPT</TableTitleCell>
                <TableTitleCell>Packs</TableTitleCell>
                <TableTitleCell>Rectifiers</TableTitleCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredSites.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredSites
              ).map((site, i) => (
                <TableRow
                  onClick={() => navigate(`info/${site.site.id}`)}
                  hover
                  key={i}
                  style={{
                    cursor: 'pointer',
                    transition: '150ms',
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <TableBodyCell>{site.site.code}</TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices &&
                    site.site.devices &&
                    site.site.devices.length > 0
                      ? site.site.devices[0].id
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell style={{ maxWidth: 200 }}>
                    {site.site.name || ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.location && site.site.location.country
                      ? site.site.location.country.name || ''
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.customer ? site.site.customer.name : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.supervisor ? site.site.supervisor.name : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices && site.site.devices.length > 0
                      ? site.site.devices[0].solar_panel_count
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices && site.site.devices.length > 0
                      ? site.site.devices[0].solar_structures
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices && site.site.devices.length > 0
                      ? site.site.devices[0].mppt_count
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices && site.site.devices.length > 0
                      ? site.site.devices[0].pack_count
                      : ''}
                  </TableBodyCell>
                  <TableBodyCell>
                    {site.site.devices && site.site.devices.length > 0
                      ? site.site.devices[0].rectifiers
                      : ''}
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBoxContainer>
      <BottomTableContainer>
        {filterByCountry && (
          <div style={{ width: 160 }}>
            <TextField
              select
              fullWidth
              size="small"
              label="Select a country"
              value={selectedCountry}
              onChange={(e) => countryFilterHandler(e.target.value)}
            >
              <MenuItem value={-1}>All</MenuItem>
              {countries.map((item, i) => (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 30, 100]}
          component="div"
          count={filteredSites.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, index) => setPage(index)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TableListPaginationActions}
        />
      </BottomTableContainer>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const BottomTableContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const TableBoxContainer = styled('div')({
  flex: 1,
  display: 'flex',
  overflowY: 'auto',
})
const TableTitleCell = styled(TableCell)(() => ({
  backgroundColor: 'inherit',
  fontWeight: 'bold',
}))
const TableBodyCell = styled(TableCell)(() => ({
  flexGrow: 0,
  flexShrink: 0,
}))

export default SitesList
