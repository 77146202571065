import { styled } from '@mui/material'
import { ActiveInactiveSitesByCountry, AsstMgmSiteList } from 'nocapp-types'
import TotalSitesChart from '../TotalSitesChart'
import CountriesActiveInactiveList from '../CountriesActiveInactiveList'
import ActiveInactiveSitesList from '../ActiveInactiveSitesList'
import { GLOBAL_PAGE_GAP } from '../../PageComponents/constants'

interface AllSitesBodyProps {
  activeSites: AsstMgmSiteList[]
  inactiveSites: AsstMgmSiteList[]
  countryCount: ActiveInactiveSitesByCountry[]
}

const AllSitesBody = ({
  activeSites,
  inactiveSites,
  countryCount,
}: AllSitesBodyProps) => {
  return (
    <Container>
      <SiteTotalContainer>
        <div
          style={{
            flex: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: 40 }}>
            {activeSites.length} | {inactiveSites.length}
          </span>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Total of active sites</span>
          <span style={{ fontWeight: 'bold' }}>Total of inactive sites</span>
        </div>
      </SiteTotalContainer>
      <CountriesTableContainer>
        <CountriesActiveInactiveList countries={countryCount} />
      </CountriesTableContainer>
      <SitesTableContainer>
        <ActiveInactiveSitesList
          active={activeSites}
          inactive={inactiveSites}
        />
      </SitesTableContainer>
      <SitesChart>
        <TotalSitesChart
          active={activeSites.length}
          inactive={inactiveSites.length}
        />
      </SitesChart>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(4, 1fr)',
  gap: GLOBAL_PAGE_GAP,
})
const CardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  padding: 10,
}))
const SiteTotalContainer = styled(CardContainer)(({ theme }) => ({
  widht: '100%',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
}))
const SitesChart = styled(CardContainer)(() => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 2,
  gridRowEnd: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const CountriesTableContainer = styled(CardContainer)(() => ({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 1,
  gridRowEnd: 5,
  display: 'flex',
}))
const SitesTableContainer = styled(CardContainer)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 4,
  gridRowStart: 1,
  gridRowEnd: 5,
  display: 'flex',
}))

export default AllSitesBody
