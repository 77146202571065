import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormEvent, useEffect, useRef, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { useAuth0 } from '@auth0/auth0-react'
import {
  FormButton,
  FormContainer,
  FormSection,
  FormSectionContainer,
  FormSectionContainerRow,
  FormSectionContainerTitle,
} from '../../../components/FormComponents'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { Android12Switch } from '../../../components/PageComponents/SwitchComponent'
import ApiHandler from '../../../services/api/apiHandler'

const CreateSitePage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [loadingProps, setLoadingProps] = useState(true)
  const [devices, setDevices] = useState<{ id: number }[]>([])
  const [selectedDevice, setSelectedDevice] = useState<number>(-1)
  const [country, setCountry] = useState<{ name: string; id: number }[]>([])
  const [zone, setZone] = useState<{ name: string; id: number }[]>([])
  const [customer, setCustomer] = useState<{ name: string; id: number }[]>([])
  const [owner, setOwner] = useState<{ name: string; id: number }[]>([])
  const [, setCabinet] = useState<{ name: string; id: number; type: string }[]>(
    []
  )
  const [battery, setBattery] = useState<{ name: string; id: number }[]>([])
  const [severity, setSeverity] = useState<
    {
      name: string
      id: number
      max_repair_time_total_loss: string
      max_repair_time_without_service_affection: string
      max_repair_time_partial_loss: string
    }[]
  >([])
  const [supervisor, setSupervisor] = useState<{ name: string; id: number }[]>(
    []
  )
  const [loading, setLoading] = useState(false)
  const siteFormRef = useRef<HTMLFormElement>(null)
  // FormControllers
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedSupervisor, setSelectedSupervisor] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  // const [selectedCabinet, setSelectedCabinet] = useState('')
  const [selectedBattery, setSelectedBattery] = useState('')
  const [selectedSeverity, setSelectedSeverity] = useState('')
  const [selectedZone, setSelectedZone] = useState('')
  const [activeSite, setActiveSite] = useState(true)
  const [genset, setGenset] = useState(false)
  const [grid, setGrid] = useState(false)
  const [addSiteDistances, setAddSiteDistances] = useState(false)
  const [addSiteSeverity, setSiteSeverity] = useState(false)
  const [newCustomer, setNewCustomer] = useState(false)
  const [newSupervisor, setNewSupervisor] = useState(false)
  const [newZone, setNewZone] = useState(false)
  const [newOwner, setNewOwner] = useState(false)
  // const [newCabinet, setNewCabinet] = useState(false)
  const [newBattery, setNewBattery] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  async function getCountries(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/countries`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const countries = await countriesFetch.json()

    //
    setCountry(countries.message)
  }

  async function getCustomers(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/customers`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setCustomer(customers.message)
  }

  async function getBatteries(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/batteries`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setBattery(customers.message)
  }

  async function getCabinets(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/cabinets`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setCabinet(customers.message)
  }

  async function getSeverities(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/severities`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setSeverity(customers.message)
  }

  async function getOwners(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/owners`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setOwner(customers.message)
  }

  async function getSupervisors(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/supervisors`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setSupervisor(customers.message)
  }

  async function getZones(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/zones`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setZone(customers.message)
  }

  const getDevicesAdminHandle = async ({
    accessToken,
  }: {
    accessToken: string
  }) => {
    const devicesList: any[] = []
    try {
      const firstPageRes = await fetch(
        `${process.env.REACT_APP_ASSET_MGM_URL}/devices`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            page: 1,
          }),
        }
      )

      if (!firstPageRes.ok) {
        throw new Error('Network response was not ok')
      }

      const firstPageData = await (await firstPageRes.json()).message
      const totalOfPages = Math.ceil(firstPageData.total / 10)

      // getting all devices
      await Promise.all(
        Array.from({ length: totalOfPages }, (_, i) => i + 1).map(
          async (page) => {
            const res = await fetch(
              `${process.env.REACT_APP_ASSET_MGM_URL}/devices`,
              {
                headers: {
                  authorization: `Bearer ${accessToken}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                  page,
                }),
              }
            )
            const data = (await res.json()).message
            devicesList.push(...data.devices)
            return data
          }
        )
      )

      setDevices(
        devicesList
          .filter((device) => device.site_id === null)
          .sort((a, b) => a.id - b.id)
      )
      return null
    } catch (e) {
      return Promise.reject(new Error('Someting went wrong, try again'))
    }
  }

  useEffect(() => {
    const getData = async () => {
      setLoadingProps(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })

      await getDevicesAdminHandle({ accessToken: token })
      await getCountries(token)
      await getCustomers(token)
      await getOwners(token)
      await getSupervisors(token)
      await getBatteries(token)
      await getCabinets(token)
      await getSeverities(token)
      await getZones(token)
      setLoadingProps(false)
    }

    getData()
  }, [getAccessTokenSilently])

  const createSite = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const apiHandler = new ApiHandler()
    // Transform data
    const formJson = apiHandler.transformFormDataToJsonWithNull(
      event.currentTarget
    )
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })

      const res = await fetch(
        `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site`,
        {
          method: 'post',
          headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: formJson.caban_id,
            name: formJson.site_name,
            customer_code: formJson.customer_id,
            owner_code: formJson.owner_id,
            active: activeSite,
            comments: formJson.commentaries,
            type: formJson.type,
            commencement_date: formJson.commencement_date,
            device_id: +formJson.modem_id,
            location: {
              name: formJson.caban_id,
              address1: formJson.address,
              latitude: +formJson.latitude,
              longitude: +formJson.longitude,
              ...(selectedZone !== '' &&
                !formJson.new_zone && {
                  zone_id: +selectedZone,
                }),
              ...(formJson.new_zone && {
                zone: {
                  name: formJson.new_zone,
                },
              }),
              country_id: +formJson.country,
            },
            severity_id: +formJson.severity || null,
            ...(selectedCustomer !== '' &&
              !formJson.customer_name && {
                customer_id: +selectedCustomer,
              }),
            ...(formJson.customer_name && {
              customer: {
                name: formJson.customer_name,
              },
            }),
            ...(selectedOwner !== '' &&
              !formJson.owner_name && {
                owner_id: +selectedOwner,
              }),
            ...(formJson.owner_name && {
              owner: {
                name: formJson.owner_name,
              },
            }),
            ...(selectedSupervisor !== '' &&
              !formJson.supervisor_name && {
                supervisor_id: +selectedSupervisor,
              }),
            ...(formJson.supervisor_name && {
              supervisor: {
                name: formJson.supervisor_name,
              },
            }),
          }),
        }
      )
      const data = await res.json()
      await fetch(
        `${process.env.REACT_APP_ASSET_MGM_URL}/device/${formJson.modem_id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'PATCH',
          body: JSON.stringify({
            name: formJson.caban_id,
            pack_count: +formJson.packs,
            mppt_count: +formJson.mppts,
            rectifiers: +formJson.rectifiers,
            solar_panel_count: +formJson.solar_panels,
            solar_structures: +formJson.solar_structures,
            genset_present: genset,
            grid_present: grid,
            contracted_load: +formJson.contracted_load,
            active: activeSite,
            configuration: formJson.configuration,
            grid_percentage: +formJson.grid_percentage,
            turn_on_date: formJson.turn_on_date,
            migration_date: formJson.migration_date,
            alerts_activation_date: formJson.alerts_activation_date,
            reports_url: formJson.report_link,
            ...(formJson.cabinet_serial &&
              formJson.cabinet_serial !== '' && {
                cabinet_serial: formJson.cabinet_serial,
              }),
          }),
        }
      )
      // updating battery and cabinet
      if (data.message.id) {
        await fetch(
          `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/${data.message!.id!}`,
          {
            method: 'put',
            headers: {
              authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              device: {
                id: +formJson.modem_id,
                cabinet: {
                  type: formJson.cabinet_type,
                  name: formJson.cabinet_serial,
                },
                ...(selectedBattery !== '' &&
                  !formJson.battery_model && {
                    battery_id: +selectedBattery,
                  }),
                ...(formJson.battery_model && {
                  supervisor: {
                    name: formJson.battery_model,
                  },
                }),
              },
            }),
          }
        )
      }
      setSnackbarSeverity('success')
      setMessage('Site created')
      setOpenSnackbar(true)
      siteFormRef.current?.reset()
    } catch (error) {
      setSnackbarSeverity('error')
      setMessage('There was an issue when creating the site')
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title="Add new site"
      loading={loadingProps}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={createSite}>
        {/* Site */}
        <FormSection
          useBackButton
          title="Add new site"
          sections={[
            [
              <TextField
                label="Caban ID"
                required
                fullWidth
                placeholder="Obligatory field"
                name="caban_id"
              />,
              <TextField
                label="Modem ID"
                required
                fullWidth
                select
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(+e.target.value)}
                placeholder="****"
                type="number"
                name="modem_id"
              >
                <MenuItem value={-1} disabled>
                  Select a device
                </MenuItem>
                {devices.map((value, i) => (
                  <MenuItem key={i} value={value.id}>
                    {value.id}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <TextField
                label="Site name"
                required
                fullWidth
                name="site_name"
              />,
              <TextField
                required
                fullWidth
                select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                label="Country"
                name="country"
              >
                <MenuItem value="" />
                {country.map((value, i) => (
                  <MenuItem key={i} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <ActiveRow>
                <FormControlLabel
                  control={
                    <Android12Switch
                      defaultChecked
                      value={activeSite}
                      onChange={() => setActiveSite(!activeSite)}
                    />
                  }
                  label="Active site"
                />
              </ActiveRow>,
            ],
          ]}
        />
        {/* Site info */}
        <FormSection
          title="Site info."
          sections={[
            [
              // New customer inputs
              newCustomer ? (
                <TextField
                  fullWidth
                  label="Customer"
                  name="customer_name"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Customer"
                  name="customer"
                  required
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  value={selectedCustomer}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {customer.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              //
              <TextField label="Customer ID" fullWidth name="customer_id" />,
            ],
            [
              // New customer inputs
              newOwner ? (
                <TextField
                  fullWidth
                  label="Owner"
                  name="owner_name"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewOwner(!newOwner)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Owner"
                  name="owner"
                  onChange={(e) => setSelectedOwner(e.target.value)}
                  value={selectedOwner}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewOwner(!newOwner)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {owner.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              <TextField label="Owner ID" fullWidth name="owner_id" />,
            ],
            [
              <TextField
                label="Contracted load (W)"
                fullWidth
                placeholder=""
                id="filled-number"
                type="number"
                inputMode="numeric"
                name="contracted_load"
                inputProps={{
                  step: 0.01,
                }}
              />,
              <TextField label="Site type" fullWidth name="type" />,
            ],
            [
              // New customer inputs
              newSupervisor ? (
                <TextField
                  fullWidth
                  label="Supervisor"
                  name="supervisor_name"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  required
                  onChange={(e) => setSelectedSupervisor(e.target.value)}
                  value={selectedSupervisor}
                  label="Supervisor"
                  name="supervisor"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {supervisor.map((sup, i) => (
                    <MenuItem key={i} value={sup.id}>
                      {sup.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Location */}
        <FormSection
          title="Location"
          sections={[
            [
              <TextField
                label="Latitude"
                fullWidth
                name="latitude"
                id="filled-number"
                inputMode="numeric"
              />,
              <TextField
                label="Longitude"
                fullWidth
                name="longitude"
                id="filled-number"
                inputMode="numeric"
              />,
            ],
            [
              newZone ? (
                <TextField
                  fullWidth
                  label="Zone"
                  name="new_zone"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewZone(!newZone)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  onChange={(e) => setSelectedZone(e.target.value)}
                  value={selectedZone}
                  label="Zone"
                  name="zone"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewZone(!newZone)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {zone.map((sup, i) => (
                    <MenuItem key={i} value={sup.id}>
                      {sup.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
            [
              <TextField
                label="Address"
                fullWidth
                multiline
                rows={4}
                name="address"
              />,
            ],
          ]}
        />
        {/* Devices info */}
        <FormSection
          title="Devices info."
          sections={[
            [
              <TextField
                label="Solar panels"
                fullWidth
                type="number"
                name="solar_panels"
              />,
              <TextField
                label="Solar structures"
                fullWidth
                type="number"
                name="solar_structures"
              />,
              <TextField
                label="Grid percentage"
                placeholder="0 - 1"
                fullWidth
                name="grid_percentage"
              />,
            ],
            [
              <TextField label="Packs" fullWidth type="number" name="packs" />,
              <TextField label="MPPT" fullWidth type="number" name="mppts" />,
              <TextField
                label="Rectifiers"
                fullWidth
                type="number"
                name="rectifiers"
              />,
            ],
            [
              <FormControlLabel
                control={
                  <Android12Switch
                    value={grid}
                    onChange={() => setGrid(!grid)}
                  />
                }
                label="Grid"
              />,
              <FormControlLabel
                control={
                  <Android12Switch
                    value={genset}
                    onChange={() => setGenset(!genset)}
                  />
                }
                label="Genset"
              />,
            ],
          ]}
        />
        {/* Devices Ids */}
        <FormSection
          title="Devices IDs"
          sections={[
            [
              <TextField
                fullWidth
                label="Cabinet SN"
                name="cabinet_serial"
                required
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderRadius: 10,
                  },
                }}
              />,
              <TextField
                label="Configuration"
                fullWidth
                name="configuration"
              />,
            ],
            [
              <TextField label="Cabinet type" fullWidth name="cabinet_type" />,
              // New customer inputs
              newBattery ? (
                <TextField
                  fullWidth
                  label="Battery model"
                  name="battery_model"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewBattery(!newBattery)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Battery model"
                  name="battery_id"
                  onChange={(e) => setSelectedBattery(e.target.value)}
                  value={selectedBattery}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewBattery(!newBattery)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {battery.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Dates */}
        <FormSection
          title="Dates"
          sections={[
            [
              <TextField
                label="Turn on date"
                fullWidth
                focused
                type="date"
                name="turn_on_date"
              />,
              <TextField
                label="Alerts activation"
                fullWidth
                focused
                type="date"
                name="alerts_activation_date"
              />,
            ],
            [
              <TextField
                label="Migration date"
                fullWidth
                focused
                type="date"
                name="migration_date"
              />,
              <TextField
                label="Commencement date"
                fullWidth
                focused
                type="date"
                name="commencement_date"
              />,
            ],
          ]}
        />
        {/* Links */}
        <FormSection
          title="Links"
          sections={[
            [
              <TextField
                label="Site information link"
                fullWidth
                name="report_link"
              />,
            ],
          ]}
        />
        {/* Commentaries */}
        <FormSection
          title="Commentaries"
          sections={[
            [
              <TextField
                label="Commentaries"
                placeholder="Place here your comments"
                multiline
                rows={5}
                fullWidth
                name="commentaries"
              />,
            ],
          ]}
        />
        {/* Site distances */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Distances</span>
            <FormControlLabel
              control={
                <Android12Switch
                  value={addSiteDistances}
                  onChange={() => setAddSiteDistances(!addSiteDistances)}
                />
              }
              label="Add site distances"
            />
          </FormSectionContainerTitle>
          {addSiteDistances && (
            <FormSectionContainerRow>
              <TextField
                label="Distance"
                placeholder="Distance in km"
                fullWidth
                name="distance"
              />
              <TextField
                label="Travel time"
                fullWidth
                placeholder="Travel time in H:m"
                name="travel_time"
              />
            </FormSectionContainerRow>
          )}
        </FormSectionContainer>
        {/* Severity */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Severity</span>
            <FormControlLabel
              control={
                <Android12Switch
                  value={addSiteSeverity}
                  onChange={() => setSiteSeverity(!addSiteSeverity)}
                />
              }
              label="Add site severity"
            />
          </FormSectionContainerTitle>
          {addSiteSeverity && (
            <>
              <FormSectionContainerRow>
                <TextField
                  select
                  fullWidth
                  label="Severity"
                  name="severity"
                  onChange={(e) => setSelectedSeverity(e.target.value)}
                  value={selectedSeverity}
                >
                  <MenuItem value="" />
                  {severity.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormSectionContainerRow>
              <FormSectionContainerRow>
                {/* <TextField
                  label="Repair time with total loss of service"
                  fullWidth
                  defaultValue={
                    severity[+selectedSeverity]?.max_repair_time_total_loss
                  }
                  name="max_repair_time_total_loss"
                />
                <TextField
                  label="Repair time with partial loss of service"
                  fullWidth
                  defaultValue={
                    severity[+selectedSeverity]?.max_repair_time_partial_loss
                  }
                  name="max_repair_time_partial_loss"
                />
                <TextField
                  label="Repair time without service affection"
                  fullWidth
                  defaultValue={
                    severity[+selectedSeverity]
                      ?.max_repair_time_without_service_affection
                  }
                  name="max_repair_time_without_service_affection"
                /> */}
              </FormSectionContainerRow>
            </>
          )}
        </FormSectionContainer>
        {/* Send */}
        <FormButton loading={loading} title="Add new site" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={snackbarSeverity}
      />
    </PageComponent>
  )
}

const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginTop: 8,
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontWeight: 'bold',
})

export default CreateSitePage
