import { useCallback, useEffect, useState } from 'react'
import { ActiveInactiveSitesByCountry, AsstMgmSiteList } from 'nocapp-types'
import { useAuth0 } from '@auth0/auth0-react'
import AllSitesBody from '../../../components/Sites/AllSitesBody'
import { activeAndInactiveSitesHandle } from './ActiveAndInactiveSitesPage.queries'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SitesSearchBar from '../../../components/Sites/SitesSearchBar'

const ActiveAndInactiveSitesPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [activeSites, setActiveSites] = useState<AsstMgmSiteList[]>([])
  const [inactiveSites, setInactiveSites] = useState<AsstMgmSiteList[]>([])
  const [countryCount, setCountryCount] = useState<
    ActiveInactiveSitesByCountry[]
  >([])

  const formatCountries = (
    active: AsstMgmSiteList[],
    inactive: AsstMgmSiteList[]
  ) => {
    let countries: any[] = []
    ;[...active, ...inactive].map((device) => {
      if (device.site.location && device.site.location.country) {
        countries.push(device.site.location.country.name)
      }
      return device
    })
    countries = countries.filter((item, pos) => countries.indexOf(item) === pos)
    countries = countries.map((country) => {
      let activeCount = 0
      let inactiveCount = 0
      active.map((activeSite) => {
        if (activeSite.site.location && activeSite.site.location.country) {
          if (activeSite.site.location.country.name === country) {
            activeCount += 1
          }
        }
        return activeSite
      })
      inactive.map((inactiveSite) => {
        if (inactiveSite.site.location && inactiveSite.site.location.country) {
          if (inactiveSite.site.location.country.name === country) {
            inactiveCount += 1
          }
        }
        return inactiveSite
      })
      return { country, active: activeCount, inactive: inactiveCount }
    })
    setCountryCount(countries)
  }

  const getData = useCallback(async () => {
    setLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const { activeSitesData, inactiveSitesData } =
      await activeAndInactiveSitesHandle(token)
    //
    setActiveSites(activeSitesData)
    setInactiveSites(inactiveSitesData)
    formatCountries(activeSitesData, inactiveSitesData)
    setLoading(false)
  }, [getAccessTokenSilently])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <PageComponent
      title="Active/Inactive sites"
      centerComponent={<SitesSearchBar />}
      loading={loading}
    >
      <AllSitesBody
        activeSites={activeSites}
        inactiveSites={inactiveSites}
        countryCount={countryCount}
      />
    </PageComponent>
  )
}

export default ActiveAndInactiveSitesPage
