import { VISITS_DB } from '../../../services/api/databases'

export const getCountryInfoQueries = (country: string) => {
  return {
    companies: `SELECT DISTINCT company FROM ${VISITS_DB} WHERE country = '${country}' ORDER BY company`,
    supervisors: `SELECT DISTINCT supervisor, supervisor_phone, supervisor_id FROM ${VISITS_DB} WHERE country = '${country}' ORDER BY supervisor`,
  }
}

export const insertVisitQuery = (body: any, site: string) =>
  `INSERT INTO ${VISITS_DB}(
  company,
  name,
  email,
  phone,
  country,
  site_id,
  work,
  activity_detail,
  start_datetime,
  end_datetime,
  supervisor,
  supervisor_phone,
  supervisor_id,
  form_from_user,
  technician_1,
  id_1,
  technician_2,
  id_2,
  technician_3,
  id_3,
  technician_4,
  id_4,
  technician_5,
  id_5
) VALUES (
  ${body.company},
  '${body.name}',
  '${body.email}',
  '${body.phone}',
  ${body.country},
  '${site}',
  ${body.work},
  ${body.activity_details},
  ${body.start_datetime},
  ${body.end_datetime},
  ${body.supervisor},
  ${body.supervisor_phone},
  ${body.supervisor_id},
  '${body.user}',
  ${body.technician_1},
  ${body.id_1},
  ${body.technician_2},
  ${body.id_2},
  ${body.technician_3},
  ${body.id_3},
  ${body.technician_4},
  ${body.id_4},
  ${body.technician_5},
  ${body.id_5}
)`
