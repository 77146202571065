/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AsstMgmSiteList } from 'nocapp-types'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { useAuth0 } from '@auth0/auth0-react'
import {
  FormButton,
  FormContainer,
  FormSection,
  FormSectionContainer,
  FormSectionContainerRow,
  FormSectionContainerTitle,
} from '../../../components/FormComponents'
import LoadingPage from '../../../components/PageComponents/LoadingPage'
import PageComponent from '../../../components/PageComponents/PageComponent'
import SnackBar, { SnackBarSeverity } from '../../../components/SnackBar'
import { Android12Switch } from '../../../components/PageComponents/SwitchComponent'
import ApiHandler from '../../../services/api/apiHandler'

const UpdateSitePage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { cabanId } = useParams<'cabanId'>()
  const [siteInfo, setSiteInfo] = useState<AsstMgmSiteList['site']>()
  const [loadingProps, setLoadingProps] = useState(true)
  const [devices, setDevices] = useState<{ id: number }[]>([])
  const [selectedDevice, setSelectedDevice] = useState<number>(-1)
  const [country, setCountry] = useState<{ name: string; id: number }[]>([])
  const [customer, setCustomer] = useState<{ name: string; id: number }[]>([])
  const [owner, setOwner] = useState<{ name: string; id: number }[]>([])

  const [, setCabinet] = useState<{ name: string; id: number; type: string }[]>(
    []
  )
  const [battery, setBattery] = useState<{ name: string; id: number }[]>([])
  const [zone, setZone] = useState<{ name: string; id: number }[]>([])
  const [severity, setSeverity] = useState<
    {
      name: string
      id: number
      max_repair_time_total_loss: string
      max_repair_time_without_service_affection: string
      max_repair_time_partial_loss: string
    }[]
  >([])
  const [supervisor, setSupervisor] = useState<{ name: string; id: number }[]>(
    []
  )
  const [loading, setLoading] = useState(false)
  // FormControllers
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedSupervisor, setSelectedSupervisor] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [activeSite, setActiveSite] = useState(true)
  const [genset, setGenset] = useState(false)
  const [grid, setGrid] = useState(false)
  const [addSiteDistances, setAddSiteDistances] = useState(false)
  const [selectedBattery, setSelectedBattery] = useState('')
  const [selectedSeverity, setSelectedSeverity] = useState('')
  const [selectedZone, setSelectedZone] = useState('')
  const [addSiteSeverity, setSiteSeverity] = useState(false)
  const [newCustomer, setNewCustomer] = useState(false)
  const [newOwner, setNewOwner] = useState(false)
  const [newSupervisor, setNewSupervisor] = useState(false)
  const [newZone, setNewZone] = useState(false)
  const [newBattery, setNewBattery] = useState(false)
  // Snackbar handlers
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarseverity, setsnackbarSeverity] =
    useState<SnackBarSeverity>('success')
  const [message, setMessage] = useState('')

  async function getCountries(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/countries`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const countries = await countriesFetch.json()

    //
    setCountry(countries.message)
  }

  async function getCustomers(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/customers`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setCustomer(customers.message)
  }

  async function getOwners(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/owners`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setOwner(customers.message)
  }

  async function getZones(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/zones`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setZone(customers.message)
  }

  async function getBatteries(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/batteries`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setBattery(customers.message)
  }

  async function getCabinets(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/cabinets`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setCabinet(customers.message)
  }

  async function getSeverities(token: string) {
    const customersFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/severities`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await customersFetch.json()

    //
    setSeverity(customers.message)
  }

  async function getSupervisors(token: string) {
    const countriesFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/supervisors`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const customers = await countriesFetch.json()

    //
    setSupervisor(customers.message)
  }

  const getDevicesAdminHandle = async ({
    accessToken,
  }: {
    accessToken: string
  }) => {
    const devicesList: any[] = []
    try {
      const firstPageRes = await fetch(
        `${process.env.REACT_APP_ASSET_MGM_URL}/devices`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            page: 1,
          }),
        }
      )

      if (!firstPageRes.ok) {
        throw new Error('Network response was not ok')
      }

      const firstPageData = await (await firstPageRes.json()).message
      const totalOfPages = Math.ceil(firstPageData.total / 10)

      // getting all devices
      await Promise.all(
        Array.from({ length: totalOfPages }, (_, i) => i + 1).map(
          async (page) => {
            const res = await fetch(
              `${process.env.REACT_APP_ASSET_MGM_URL}/devices`,
              {
                headers: {
                  authorization: `Bearer ${accessToken}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                  page,
                }),
              }
            )
            const data = (await res.json()).message
            devicesList.push(...data.devices)
            return data
          }
        )
      )

      setDevices(
        devicesList
          .filter(
            (device) => device.site_id === null || device.site_id === +cabanId!
          )
          .sort((a, b) => a.id - b.id)
      )
      return null
    } catch (e) {
      return Promise.reject(new Error('Someting went wrong, try again'))
    }
  }

  useEffect(() => {
    const getData = async () => {
      setLoadingProps(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })

      await getDevicesAdminHandle({ accessToken: token })
      await getCountries(token)
      await getCustomers(token)
      await getOwners(token)
      await getSupervisors(token)
      await getBatteries(token)
      await getCabinets(token)
      await getSeverities(token)
      await getZones(token)
      setLoadingProps(false)
    }

    getData()
  }, [getAccessTokenSilently])

  const getSiteInfo = useCallback(async () => {
    setLoadingProps(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
    })
    const siteFetch = await fetch(
      `${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/${cabanId}/details`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    const siteData = (await siteFetch.json()).message[0]
      .site as AsstMgmSiteList['site']
    setSelectedDevice(
      siteData.devices && siteData.devices?.length > 0
        ? siteData.devices[0].id
        : -1
    )
    // Data for controllers
    if (siteData.location && siteData.location.country) {
      setSelectedCountry(`${siteData.location?.country.id!}`)
    }
    setSelectedCustomer(
      siteData.customer !== null ? `${siteData.customer.id}` : ''
    )
    setSelectedSupervisor(
      siteData.supervisor !== null ? `${siteData.supervisor.id}` : ''
    )
    setSelectedOwner(siteData.owner !== null ? `${siteData.owner.id}` : '')
    setSelectedZone(
      siteData.location?.zone !== null ? `${siteData.location?.zone_id}` : ''
    )
    setSelectedBattery(
      siteData.devices &&
        siteData.devices?.length > 0 &&
        siteData.devices[0].battery !== null
        ? `${siteData.devices[0].battery?.id}`
        : ''
    )
    setActiveSite(siteData.active)
    setGenset(
      siteData.devices && siteData.devices?.length > 0
        ? siteData.devices[0].genset_present
        : false
    )
    setGrid(
      siteData.devices && siteData.devices?.length > 0
        ? siteData.devices[0].grid_present
        : false
    )
    // setAddSiteDistances(siteInfoData.distance !== null)
    setSiteSeverity(!!siteData.severity)
    //
    setSiteInfo(siteData)
    setLoadingProps(false)
  }, [cabanId, getAccessTokenSilently])

  useEffect(() => {
    getSiteInfo()
  }, [getSiteInfo])

  if (!siteInfo) {
    return <LoadingPage />
  }

  function formatDate(datestring: string | null) {
    if (!datestring) {
      return ''
    }
    const date = new Date(datestring)
    let day: string | number = date.getDate()
    if (day < 10) {
      day = `0${day}`
    }
    let month: string | number = date.getMonth() + 1
    if (month < 10) {
      month = `0${month}`
    }
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }

  const updateSite = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      const apiHandler = new ApiHandler()
      // Transform data
      const formJson = apiHandler.transformFormDataToJsonWithNull(
        event.currentTarget
      )

      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_NOCAPP_AUTH0_AUDIENCE,
      })

      await fetch(`${process.env.REACT_APP_ASSET_MGM_URL}/v1/site/${cabanId}`, {
        method: 'put',
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: formJson.caban_id,
          name: formJson.site_name,
          customer_code: formJson.customer_id,
          owner_code: formJson.owner_id,
          active: activeSite,
          comments: formJson.commentaries,
          type: formJson.type,
          commencement_date: formJson.commencement_date,
          device_id: +formJson.modem_id,
          location: {
            name: formJson.caban_id,
            address1: formJson.address,
            latitude: +formJson.latitude,
            longitude: +formJson.longitude,
            ...(selectedZone !== '' &&
              !formJson.new_zone && {
                zone_id: +selectedZone,
              }),
            ...(formJson.new_zone && {
              zone: {
                name: formJson.new_zone,
              },
            }),
            country_id: +formJson.country,
          },
          severity_id: +formJson.severity || null,
          ...(selectedCustomer !== '' &&
            !formJson.customer_name && {
              customer_id: +selectedCustomer,
            }),
          ...(formJson.customer_name && {
            customer: {
              name: formJson.customer_name,
            },
          }),
          ...(selectedOwner !== '' &&
            !formJson.new_owner && {
              owner_id: +selectedOwner,
            }),
          ...(formJson.new_owner && {
            owner: {
              name: formJson.new_owner,
            },
          }),
          ...(selectedSupervisor !== '' &&
            !formJson.new_supervisor && {
              supervisor_id: +selectedSupervisor,
            }),
          ...(formJson.new_supervisor && {
            supervisor: {
              name: formJson.supervisor_name,
            },
          }),
          device: {
            id: +formJson.modem_id,
            cabinet: {
              type: formJson.cabinet_type,
              name: formJson.cabinet_serial,
            },
            ...(selectedBattery !== '' &&
              !formJson.battery_model && {
                battery_id: +selectedBattery,
              }),
            ...(formJson.battery_model && {
              supervisor: {
                name: formJson.battery_model,
              },
            }),
          },
        }),
      })
      await fetch(
        `${process.env.REACT_APP_ASSET_MGM_URL}/device/${formJson.modem_id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'PATCH',
          body: JSON.stringify({
            name: formJson.caban_id,
            pack_count: +formJson.packs,
            mppt_count: +formJson.mppts,
            rectifiers: +formJson.rectifiers,
            solar_panel_count: +formJson.solar_panels,
            solar_structures: +formJson.solar_structures,
            genset_present: genset,
            grid_present: grid,
            contracted_load: +formJson.contracted_load,
            active: activeSite,
            configuration: formJson.configuration,
            grid_percentage: +formJson.grid_percentage,
            turn_on_date: formJson.turn_on_date,
            migration_date: formJson.migration_date,
            alerts_activation_date: formJson.alerts_activation_date,
            reports_url: formJson.report_link,
            ...(formJson.cabinet_serial &&
              formJson.cabinet_serial !== '' && {
                cabinet_serial: formJson.cabinet_serial,
              }),
          }),
        }
      )
      setsnackbarSeverity('success')
      setMessage(`${siteInfo.code} updated`)
      setOpenSnackbar(true)
    } catch (error) {
      setsnackbarSeverity('warning')
      setMessage("There's been an error in the server")
      setOpenSnackbar(true)
    }
    setLoading(false)
  }

  return (
    <PageComponent
      title={`Update ${siteInfo.code}`}
      loading={loadingProps}
      scrollable
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormContainer onSubmit={updateSite}>
        {/* Site */}
        <FormSection
          useBackButton
          title="Update site"
          sections={[
            [
              <TextField
                label="Caban ID"
                required
                fullWidth
                placeholder="Obligatory field"
                name="caban_id"
                defaultValue={siteInfo.code}
              />,
              <TextField
                label="Modem ID"
                required
                fullWidth
                select
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(+e.target.value)}
                placeholder="****"
                type="number"
                name="modem_id"
              >
                <MenuItem value={-1} disabled>
                  Select a device
                </MenuItem>
                {devices.map((value, i) => (
                  <MenuItem key={i} value={value.id}>
                    {value.id}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <TextField
                label="Site name"
                required
                fullWidth
                name="site_name"
                defaultValue={siteInfo.name}
              />,
              <TextField
                required
                fullWidth
                select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                label="Country"
                name="country"
              >
                <MenuItem value="" />
                {country.map((value, i) => (
                  <MenuItem key={i} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </TextField>,
            ],
            [
              <ActiveRow>
                <FormControlLabel
                  control={
                    <Android12Switch
                      checked={activeSite}
                      onChange={() => setActiveSite(!activeSite)}
                    />
                  }
                  label="Active site"
                />
              </ActiveRow>,
            ],
          ]}
        />
        {/* Site info */}
        <FormSection
          title="Site info."
          sections={[
            [
              // New customer inputs
              newCustomer ? (
                <TextField
                  fullWidth
                  label="Customer"
                  name="customer_name"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Customer"
                  name="customer_id"
                  required
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  value={selectedCustomer}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewCustomer(!newCustomer)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {customer.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              //
              <TextField
                label="Customer ID"
                fullWidth
                name="customer_id"
                defaultValue={siteInfo.customer_code}
              />,
            ],
            [
              newOwner ? (
                <TextField
                  fullWidth
                  label="Owner"
                  name="new_owner"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewOwner(!newOwner)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Owner"
                  name="owner"
                  onChange={(e) => setSelectedOwner(e.target.value)}
                  value={selectedOwner}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewOwner(!newOwner)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {owner.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              <TextField
                label="Owner ID"
                fullWidth
                name="owner_id"
                defaultValue={siteInfo.owner_code}
              />,
            ],
            [
              <TextField
                label="Contracted load"
                fullWidth
                placeholder=""
                type="number"
                inputMode="numeric"
                name="contracted_load"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].contracted_load
                    : ''
                }
                inputProps={{
                  step: 0.01,
                }}
              />,
              <TextField
                label="Site type"
                fullWidth
                name="type"
                defaultValue={siteInfo.type}
              />,
            ],
            [
              // New customer inputs
              newSupervisor ? (
                <TextField
                  fullWidth
                  label="Supervisor"
                  name="supervisor"
                  required
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  required
                  onChange={(e) => setSelectedSupervisor(e.target.value)}
                  value={selectedSupervisor}
                  label="Supervisor"
                  name="supervisor"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewSupervisor(!newSupervisor)}
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {supervisor.map((sup, i) => (
                    <MenuItem key={i} value={sup.id}>
                      {sup.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Location */}
        <FormSection
          title="Location"
          sections={[
            [
              <TextField
                label="Latitude"
                fullWidth
                name="latitude"
                defaultValue={siteInfo.location?.latitude}
              />,
              <TextField
                label="Longitude"
                fullWidth
                name="longitude"
                defaultValue={siteInfo.location?.longitude}
              />,
            ],
            [
              newZone ? (
                <TextField
                  fullWidth
                  label="Zone"
                  name="new_zone"
                  defaultValue={siteInfo.location?.zone?.name}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewZone(!newZone)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  onChange={(e) => setSelectedZone(e.target.value)}
                  value={selectedZone}
                  label="Zone"
                  name="zone"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewZone(!newZone)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {zone.map((sup, i) => (
                    <MenuItem key={i} value={sup.id}>
                      {sup.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
            [
              <TextField
                label="Address"
                fullWidth
                multiline
                rows={4}
                name="address"
                defaultValue={siteInfo.location?.address1}
              />,
            ],
          ]}
        />
        {/* Devices info */}
        <FormSection
          title="Devices info."
          sections={[
            [
              <TextField
                label="Solar panels"
                fullWidth
                type="number"
                name="solar_panels"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].solar_panel_count
                    : ''
                }
              />,
              <TextField
                label="Solar structures"
                fullWidth
                type="number"
                name="solar_structures"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].solar_structures
                    : ''
                }
              />,
              <TextField
                label="Grid percentage"
                placeholder="0 - 1"
                fullWidth
                name="grid_percentage"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].grid_percentage
                    : ''
                }
              />,
            ],
            [
              <TextField
                label="Packs"
                fullWidth
                type="number"
                name="packs"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].pack_count
                    : ''
                }
              />,
              <TextField
                label="MPPTs"
                fullWidth
                type="number"
                name="mppts"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].mppt_count
                    : ''
                }
              />,
              <TextField
                label="Rectifiers"
                fullWidth
                type="number"
                name="rectifiers"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].rectifiers
                    : ''
                }
              />,
            ],
            [
              <FormControlLabel
                control={
                  <Android12Switch
                    checked={grid}
                    onChange={() => setGrid(!grid)}
                  />
                }
                label="Grid"
              />,
              <FormControlLabel
                control={
                  <Android12Switch
                    checked={genset}
                    onChange={() => setGenset(!genset)}
                  />
                }
                label="Genset"
              />,
            ],
          ]}
        />
        {/* Devices Ids */}
        <FormSection
          title="Devices IDs"
          sections={[
            [
              <TextField
                label="Cabinet SN"
                fullWidth
                name="cabinet_serial"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].cabinet?.name
                    : ''
                }
              />,
              <TextField
                label="Configuration"
                fullWidth
                name="configuration"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].configuration
                    : ''
                }
              />,
            ],
            [
              <TextField
                label="Cabinet type"
                fullWidth
                name="cabinet_type"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].cabinet?.type
                    : ''
                }
              />,
              newBattery ? (
                <TextField
                  fullWidth
                  label="Battery model"
                  name="battery_model"
                  defaultValue={
                    siteInfo.devices && siteInfo.devices.length > 0
                      ? siteInfo.devices[0].battery?.name
                      : ''
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewBattery(!newBattery)}>
                          <FormatListBulletedRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  label="Battery model"
                  name="battery_id"
                  onChange={(e) => setSelectedBattery(e.target.value)}
                  value={selectedBattery}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setNewBattery(!newBattery)}>
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" />
                  {battery.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            ],
          ]}
        />
        {/* Dates */}
        <FormSection
          title="Dates"
          sections={[
            [
              <TextField
                label="Turn on date"
                fullWidth
                focused
                type="date"
                name="turn_on_date"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? formatDate(siteInfo.devices[0].turn_on_date)
                    : ''
                }
              />,
              <TextField
                label="Alerts activation"
                fullWidth
                focused
                type="date"
                name="alerts_activation_date"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? formatDate(siteInfo.devices[0].alerts_activation_date)
                    : ''
                }
              />,
            ],
            [
              <TextField
                label="Migration date"
                fullWidth
                focused
                type="date"
                name="migration_date"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? formatDate(siteInfo.devices[0].migration_date)
                    : ''
                }
              />,
              <TextField
                label="Commencement date"
                fullWidth
                focused
                type="date"
                name="commencement_date"
                defaultValue={formatDate(siteInfo.commencement_date)}
              />,
            ],
          ]}
        />
        {/* Links */}
        <FormSection
          title="Links"
          sections={[
            [
              <TextField
                label="Site information link"
                fullWidth
                name="report_link"
                defaultValue={
                  siteInfo.devices && siteInfo.devices.length > 0
                    ? siteInfo.devices[0].reports_url
                    : ''
                }
              />,
            ],
          ]}
        />
        {/* Commentaries */}
        <FormSection
          title="Commentaries"
          sections={[
            [
              <TextField
                label="Commentaries"
                placeholder="Place here your comments"
                multiline
                rows={5}
                fullWidth
                name="commentaries"
                defaultValue={siteInfo.comments}
              />,
            ],
          ]}
        />
        {/* Site distances */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Distances</span>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={addSiteDistances}
                  onChange={() => setAddSiteDistances(!addSiteDistances)}
                />
              }
              label="Add site distances"
            />
          </FormSectionContainerTitle>
          {addSiteDistances && (
            <FormSectionContainerRow>
              <TextField
                label="Distance"
                placeholder="Distance in km"
                fullWidth
                name="distance"
                // defaultValue={siteInfo.distance}
              />
              <TextField
                label="Travel time"
                fullWidth
                placeholder="Travel time in H:m"
                name="travel_time"
                // defaultValue={siteInfo.travel_time}
              />
            </FormSectionContainerRow>
          )}
        </FormSectionContainer>
        {/* Severity */}
        <FormSectionContainer>
          <FormSectionContainerTitle>
            <span>Site Severity</span>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={addSiteSeverity}
                  onChange={() => setSiteSeverity(!addSiteSeverity)}
                />
              }
              label="Add site severity"
            />
          </FormSectionContainerTitle>
          {addSiteSeverity && (
            <>
              <FormSectionContainerRow>
                <TextField
                  select
                  fullWidth
                  label="Severity"
                  name="severity"
                  onChange={(e) => setSelectedSeverity(e.target.value)}
                  value={selectedSeverity}
                >
                  <MenuItem value="" />
                  {severity.map((value, i) => (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormSectionContainerRow>
              <FormSectionContainerRow>
                {/* <TextField
                  label="Repair time with total loss of service"
                  fullWidth
                  name="max_repair_time_total_loss"
                  defaultValue={siteInfo.severity?.max_repair_time_total_loss}
                />
                <TextField
                  label="Repair time with partial loss of service"
                  fullWidth
                  name="max_repair_time_partial_loss"
                  defaultValue={siteInfo.severity?.max_repair_time_partial_loss}
                />
                <TextField
                  label="Repair time without service affection"
                  fullWidth
                  name="max_repair_time_without_service_affection"
                  defaultValue={
                    siteInfo.severity?.max_repair_time_without_service_affection
                  }
                /> */}
              </FormSectionContainerRow>
            </>
          )}
        </FormSectionContainer>
        {/* Send */}
        <FormButton loading={loading} title="Update site" />
      </FormContainer>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        severity={snackbarseverity}
      />
    </PageComponent>
  )
}

const ActiveRow = styled('div')({
  width: '100%',
  display: 'flex',
  marginTop: 8,
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontWeight: 'bold',
})

export default UpdateSitePage
